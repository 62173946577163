/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import dualAbi from "../Abi/dual.json";
import multicallAbi from "../Abi/multicall.json";
// import { RaceModul } from "../Base/RaceModul"
// import { FormulaModul } from "../Pages/F1/components/DriverList"
import * as chains from "../Components/Chains"
// import axios from 'axios'

/* -------------------------------------------------------------------------- */
/*                                    UTILS                                   */
/* -------------------------------------------------------------------------- */

const MULTICALL_BATCHES_LIMIT = 200;

// const txOptions = { gasLimit: "" };

const CATEGORIES_DATA = [{
  name: "MMA",
  passName: "mma",
  predCount: 4,
  category: 0
}, {
  name: "F1",
  passName: "formula1",
  predCount: 10,
  category: 1
}, {
  name: "CRYPTO",
  passName: "crypto",
  predCount: 10, // to define?
  category: 2
}]

const ethereumEvents = ["accountsChanged", "connect", "disconnect"]

const allowedNetworks = [
    // "0xa4b1", // arbitrum
    "0x7a69", // localhost
    "0x2b74", // localhost abstract
    // "0x153e6923d4b", // dao testnet
]

const txOptions = { gasLimit: "1000000" };


const shortedAddress = address => {
    const len = address.length
    const start = address.slice(0, 4)
    const end = address.slice(len - 4, len)
    return start + "..." + end
}

export const FirstContext = createContext()

const FirstContextProvider = (props) => {

    /* -------------------------------------------------------------------------- */
    /*                               STATE VARIABLES                              */
    /* -------------------------------------------------------------------------- */

    const [signer, setSigner] = useState(null);
    const [account, setAccount] = useState(null);
    const [allowedNetwork, setAllowedNetwork] = useState(false);
    const [dual, setDual] = useState(null);
    const [multicall, setMulticall] = useState(null);
    const [dualInterface, setDualInterface] = useState(null);
    const [contractsAttached, setContractsAttached] = useState(false);
    // const [alreadyPlayed, setAlreadyPlayed] = useState(true);
    // const [fundRequest, setFundRequest] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [chainId, setChainId] = useState(null);
    const [sessionsCount, setSessionsCount] = useState(0);
    const [dualSessions, setDualSessions] = useState([]);
    const [playersList, setPlayersList] = useState([]);
    const [playerPositions, setPlayerPositions] = useState([]);
    const [playerRewards, setPlayerRewards] = useState([]);
    const [playerChoices, setPlayerChoices] = useState([]);
    const [tx, setTx] = useState(0);

    /* -------------------------------------------------------------------------- */
    /*                                  EFFECTS                                   */
    /* -------------------------------------------------------------------------- */

    /* ---------------------------- account - chainId --------------------------- */
    useEffect(() => {
        if (signer) {
            setAccount(signer.address);
            updateChainId();
        } else {
            setAccount(null);
            setChainId(null);
        }
    }, [signer])


    /* ---------------------------- allowed networks ---------------------------- */
    useEffect(() => {
        if (chainId) {
            if (allowedNetworks.includes(chainId)) {
                setAllowedNetwork(true);
            } else {
                setAllowedNetwork(false);
            }
        }
    }, [chainId])


    /* -------------------------------- contracts ------------------------------- */
    useEffect(() => {
        if (
            signer &&
            account &&
            allowedNetworks &&
            process.env.REACT_APP_DUAL_MODE &&
            process.env.REACT_APP_PRICE_MULTICALL
        ) {
            try {
                setDual(new ethers.Contract(process.env.REACT_APP_DUAL_MODE, dualAbi, signer));
                setDualInterface(new ethers.Interface(dualAbi));
                setMulticall(new ethers.Contract(process.env.REACT_APP_PRICE_MULTICALL, multicallAbi, signer));
                setContractsAttached(true);
            } catch (e) {
                console.log(e.message)
                setContractsAttached(false);
            }
        } else {
            setDual(null);
            setDualInterface(null);
            setMulticall(null);
            setContractsAttached(false);
        }
    }, [signer, account, allowedNetwork]);


    /* --------------------------- fetch contract data -------------------------- */
    useEffect(() => {
        if (contractsAttached) {
            getDualData();
        }
    }, [contractsAttached, tx, sessionsCount])


    /* ---------------------------- fund api request ---------------------------- */
    // useEffect(() => {
    //     try {
    //         if (allowedNetwork && account && signer && !fundRequest) {
    //             setFundRequest(true)
    //             signer.provider.getBalance(account).then(balance => {
    //                 if (balance.toString() === "0") {
    //                     signer.signMessage(process.env.REACT_APP_MESSAGE, account).then(signature => {

    //                         const baseUrl = "https://balance-api.defiallodds.io/"
    //                         const url = baseUrl + "fund"

    //                         const postObj = {
    //                             method: 'post',
    //                             url,
    //                             headers: {},
    //                             data: {
    //                                 signature,
    //                                 address: account
    //                             }
    //                         }
    //                         setOpenBackdrop(true)
    //                         axios(postObj).then(res => {
    //                             if (res.data.includes("was funded 1 SPI:")) {
    //                                 setOpenBackdrop(false)
    //                                 setOpenSuccessMessage(true)
    //                             }
    //                         })

    //                     })
    //                 }
    //             })
    //         }
    //     } catch (e) {
    //         setOpenBackdrop(false)
    //         setFundRequest(false)
    //     }
    // }, [allowedNetwork, account])

    /* -------------------------------------------------------------------------- */
    /*                                  UTILS FUNCTIONS                           */
    /* -------------------------------------------------------------------------- */

    const updateSigner = async () => {
        if (window.ethereum) {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum, "any")
                const newSigner = await provider.getSigner();
                setSigner(newSigner);

                ethereumEvents.map(async (element) => {
                    await window.ethereum.on(element, () => {
                        window.location.reload()
                    });
                });
            } catch (e) {
                console.log(e.message)
            }
        } else {
            setSigner(null);
        }
    }

    const addNetwork = async (chainData) => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [chainData]
                });

                updateSigner();
            } catch (e) {
                console.log(e.message);
            }
        }
    }


    const connectWallet = async () => {
        try {
            addNetwork(chains.AbstractTestnet);
        } catch (e) {
            console.log(e.message)
        }
    }

    const disconnectWallet = async () => {
        try {
            if (window.ethereum) {
                await window.ethereum.request({
                    method: "eth_requestAccounts",
                    params: [{ eth_accounts: {} }]
                })
                setSigner(null);
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const updateChainId = async () => {
        try {
            window.ethereum.request({ method: 'eth_chainId' }).then(chainId => {
                setChainId(chainId);
            })
        } catch (e) {
            console.log(e.message)
        }
    }

    const getDualData = async () => {
        try {
            const fetchedSessionCount = await getDualSessionsCount();
            setSessionsCount(fetchedSessionCount);

            if (fetchedSessionCount > 0) {
                await getDualSessions();
                await getPlayersList();
                await getPlayerPositions();
                await getPlayerRewards();
                await getPlayerChoices();
            }

        } catch (e) {
            console.log(e.message);
        }
    }

    const getDualSessionsCount = async () => {
        try {
            const count = await dual.getSessionsCount();
            return parseInt(count);
        } catch (e) {
            console.log(e.message);
            return 0;
        }
    }


    const getDualSessions = async () => {
        try {
            if (sessionsCount === 0) setDualSessions([]);

            const getSessionSignature = "getSession(uint256)";

            const targetList = [];
            const dataList = [];

            for (let i = 0; i < sessionsCount; i++) {
                targetList.push(process.env.REACT_APP_DUAL_MODE);
                dataList.push(dualInterface.encodeFunctionData(getSessionSignature, [i]));
            }

            const results = await decodedFunctionResults(targetList, dataList);

            setDualSessions(results);
        } catch (e) {
            console.log(e.message);
            setDualSessions([]);
        }
    }

    const splitBatches = async (targetList, dataList) => {
        try {
            if (dataList.length <= MULTICALL_BATCHES_LIMIT) {
                return parse((await multicall.multiCall(targetList, dataList)));
            } else {
                let results = [];
                let offset = 0;

                const fullBatches = parseInt(targetList.length / MULTICALL_BATCHES_LIMIT);

                for (let i = 0; i < fullBatches; i++) {
                    const partialResults = parse(await multicall.multiCall(targetList.slice(offset, offset + MULTICALL_BATCHES_LIMIT), dataList.slice(offset, offset + MULTICALL_BATCHES_LIMIT)));
                    results.concat(partialResults);
                    offset += MULTICALL_BATCHES_LIMIT;
                }

                const remainingResultsCount = targetList.length % MULTICALL_BATCHES_LIMIT;
                if (remainingResultsCount > 0) {
                    const remainingResults = parse(await multicall.multiCall(targetList.slice(offset, offset + remainingResultsCount), dataList.slice(offset, offset + remainingResultsCount)));
                    results.concat(remainingResults);
                }

                return results;
            }
        } catch (e) {
            console.log(e.message);
            return [];
        }
    }


    const parse = obj => {
        try {
            return JSON.parse(JSON.stringify(obj, (key, value) =>
                typeof value === 'bigint'
                    ? value.toString()
                    : value
            ));
        } catch (e) {
            console.log(e.message);
        }
    }


    const getPlayersList = async () => {
        try {
            if (sessionsCount === 0) return setPlayersList([]);

            const playersLengthList = await getPlayersLengthList();
            const results = []

            for (let i = 0; i < playersLengthList.length; i++) {
                const targetList = [];
                const dataList = [];
                for (let j = 0; j < playersLengthList[i]; j++) {
                    targetList.push(process.env.REACT_APP_DUAL_MODE);
                    dataList.push(dualInterface.encodeFunctionData("getSessionPlayer(uint256,uint256)", [i, j]));
                }
                const partialResults = await decodedFunctionResults(targetList, dataList);

                results.push(partialResults);
            }

            setPlayersList(results);
        } catch (e) {
            console.log(e.message);
            setPlayersList([]);
        }
    }

    const getPlayersLengthList = async () => {
        try {
            if (sessionsCount === 0) return [];

            const targetList = [];
            const dataList = [];

            for (let i = 0; i < sessionsCount; i++) {
                targetList.push(process.env.REACT_APP_DUAL_MODE);
                dataList.push(dualInterface.encodeFunctionData("getSessionPlayersLength(uint256)", [i]));
            }

            return await decodedFunctionResults(targetList, dataList);
        } catch (e) {
            console.log(e.message);
            return [];
        }
    }

    const decodedFunctionResults = async (targetList, dataList) => {
        try {
            if (targetList.length === 0) return [];
            const encodedResult = await splitBatches(targetList, dataList);
            const results = [];
            for (let i = 0; i < dataList.length; i++) {
                const fragment = dualInterface.getFunction(dataList[i].slice(0, 10));
                const partialResults = parse(dualInterface.decodeFunctionResult(fragment, encodedResult[i]))[0];
                results.push(partialResults);
            }
            return results;
        } catch (e) {
            console.log(e.message);
            return [];
        }
    }


    const getPlayerPositions = async () => {
        try {
            if (sessionsCount === 0) setPlayerPositions([]);
            const results = [];
            for (let i = 0; i < playersList.length; i++) {
                const targetList = [];
                const dataList = [];

                for (let j = 0; j < playersList[i].length; j++) {
                    targetList.push(process.env.REACT_APP_DUAL_MODE);
                    dataList.push(dualInterface.encodeFunctionData("getPlayerPosition(uint256,address)", [i, playersList[i][j]]));
                }

                const patialResults = await decodedFunctionResults(targetList, dataList);

                results.push(patialResults);
            }

            setPlayerPositions(results);
        } catch (e) {
            console.log(e.message);
            setPlayerPositions([]);
        }
    }

    const getPlayerRewards = async () => {
        try {
            if (sessionsCount === 0) setPlayerRewards([]);
            const results = [];
            for (let i = 0; i < playersList.length; i++) {
                const targetList = [];
                const dataList = [];

                for (let j = 0; j < playersList[i].length; j++) {
                    targetList.push(process.env.REACT_APP_DUAL_MODE);
                    dataList.push(dualInterface.encodeFunctionData("getPlayerRewards(uint256,address)", [i, playersList[i][j]]));
                }

                const patialResults = await decodedFunctionResults(targetList, dataList);
                results.push(patialResults);
            }

            setPlayerRewards(results);
        } catch (e) {
            console.log(e.message);
            setPlayerRewards([]);
        }
    }

    const getPlayerChoices = async () => {
        try {
            if (sessionsCount === 0) setPlayerChoices([]);
            if (!account) setPlayerChoices([]);
            
            const targetList = [];
            const dataList = [];
            for (let i = 0; i < sessionsCount; i++) {
                targetList.push(process.env.REACT_APP_DUAL_MODE);
                dataList.push(dualInterface.encodeFunctionData("getPlayerChoices(uint256,address)", [i, account]));
            }                

            const results = await decodedFunctionResults(targetList, dataList);
            setPlayerChoices(results);
        } catch (e) {
            console.log(e.message);
            setPlayerChoices([]);
        }
    }

    const getStaticCategoryData =  categoryName => {
        const result = CATEGORIES_DATA.filter(el => el.name === categoryName);
        if (result.length > 0) return result[0];
        else return [];
    }

    const filterOnChainDataByCategory = category => {
        if(!dualSessions) return [];
        if(dualSessions.length === 0) return[];
        return dualSessions.filter(el => parseInt(el?.[6]) === category)
    }


    const bet = async (active, alreadyPlayed, choices) => {
        const now = parseInt(new Date() / 1000);
        if (
            signer &&
            account &&
            allowedNetwork &&
            dual &&
            sessionsCount > 0 &&
            dualSessions.length > 0 &&
            active >= 0 &&
            active < dualSessions.length &&
            !alreadyPlayed &&
            choices.length === parseInt(dualSessions[active][3]) &&
            choices.every(el => typeof el === "boolean") &&
            now < parseInt(dualSessions[active][1])
        ) {
            try {
                    setOpenBackdrop(true)
                    await dual.bet.staticCall( // or staticCall?
                        active,
                        choices,
                        [],
                        txOptions
                    )
                    const sentTx = await dual.bet(
                        active,
                        choices,
                        [],
                        txOptions
                    )


                    const receipt = await sentTx.wait();
                    setOpenBackdrop(false)

                    if (parseInt(receipt.status) === 1) {
                        setOpenSuccessMessage(true);
                        setTx(tx + 1);
                    } else {
                        setOpenErrorMessage(false)
                    }
            } catch (e) {
                console.log(e.message)
                setOpenBackdrop(false)
                setOpenErrorMessage(false)
            }

        }
    }

    /* -------------------------------------------------------------------------- */
    /*                              CONTEXT VARIABLES                             */
    /* -------------------------------------------------------------------------- */

    return (
        <FirstContext.Provider value={{
            account,
            connectWallet,
            disconnectWallet,
            shortedAddress,
            signer,
            allowedNetwork,
            chainId,
            sessionsCount,
            dualSessions,
            playersList,
            playerPositions,
            playerRewards,
            openMessage,
            setOpenMessage,
            openSuccessMessage,
            setOpenSuccessMessage,
            openErrorMessage,
            setOpenErrorMessage,
            openBackdrop,
            setOpenBackdrop,
            getStaticCategoryData,
            filterOnChainDataByCategory,
            playerChoices,
            bet
        }}>
            {props.children}
        </FirstContext.Provider>
    )
}

export default FirstContextProvider