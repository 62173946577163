import React from "react";
import { closeIcon } from "../SVG";

export default function InstructionMod({ setModal, pass }) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-elem"></div>
      <div className="modal__inner-title">
        <h3 className="sm">Face Off</h3>
        <h6 className="sm">Instructions</h6>
      </div>
      <div className="modal__inner-head">
        <h3 className="sm">Who will be victorious?</h3>
        {!pass?.mma ? (
          <p>
            Select one fighter per pool. If your picked fighter beats his
            opponent, you can earn SPI tokens, and get ranked in the leaderboard
            by subscribing to the <span>Spredict Pass</span>.
          </p>
        ) : (
          <p>
            Select one fighter per pool. If your picked fighter beats his
            opponent, you earn points, and get ranked in the leaderboard. Be
            ranked in a good position in the leaderboard to earn a monthly
            reward in cryptocurrency.
          </p>
        )}
      </div>
      <div className="modal__inner-steps">
        <div className="modal__inner-step">
          <h6>Step 1</h6>
          <p>Choose one fighter per pool to beat their opponent.</p>
        </div>
        <div className="modal__inner-step">
          <h6>Step 2</h6>
          <p>
            Validate your selection through a free transaction in the
            blockchain. <br />
            <br />
            To add an additional strategic element, Face Off includes the
            "favorite" feature. Depending on if you choose the favorite pick
            represented by the star icon, or make a contrarian play, you can
            earn one or three points depending on whether your pick is the one
            that wins.
            <br /> <b>Let’s Play!</b>
          </p>
        </div>
      </div>
    </>
  );
}
