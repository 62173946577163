import React, { useState } from "react";
import { checkIcon, formulaIcon, mmaIcon } from "../SVG";
import PassSelect from "../PassSelect";

const list = [
  {
    id: "1",
    title: "Formula 1",
    name: "formula1",
    icon: formulaIcon,
    image: "/images/icons/formulaGoldIcon.svg",
  },
  {
    id: "2",
    title: "MMA",
    name: "mma",
    icon: mmaIcon,
    image: "/images/icons/mmaGoldIcon.svg",
  },
  {
    id: "3",
    title: "Crypto",
    name: "crypto",
    icon: "/images/icons/cryptoIcon.svg",
    image: "/images/icons/cryptoGoldIcon.svg",
  },
];

const passList = [
  { id: "1", value: "Monthly Pass" },
  { id: "2", value: "Season Pass" },
];

export default function PremiumMod({ setModal, pass, setModalData }) {
  const [pos, setPos] = useState({
    formula1: { hasPass: pass?.formula1, select: passList[0] },
    mma: { hasPass: pass?.mma, select: passList[0] },
    crypto: { hasPass: pass?.crypto, select: passList[0] },
  });
  const updatePos = (data) => {
    setPos((pos) => ({ ...pos, ...data }));
  };
  const [count, setCount] = useState(0);
  return (
    <>
      <div className="modal__inner-elem"></div>
      <div className="modalPremium">
        <div className="modalPremium__title">
          <h4>Get your Spredict pass!</h4>
          <p>select the sports you want to subscribe to as a SPREDICT PASS</p>
        </div>
        <div className="modalPremium__items">
          {list?.map((item, index) => {
            return (
              <PremiumItem
                itemData={item}
                key={index}
                pass={pass}
                pos={pos}
                updatePos={updatePos}
                setCount={setCount}
                count={count}
                passList={passList}
              />
            );
          })}
        </div>
        <div className="modalPremium__footer">
          <button
            type="button"
            disabled={count === 0}
            className={"modalPremium__btn "}
            onClick={() => {
              setModal("connect");
              setModalData(pos);
            }}
          >
            checkout
          </button>
        </div>
      </div>
    </>
  );
}
const PremiumItem = ({
  itemData,
  pass,
  pos,
  updatePos,
  setCount,
  count,
  passList,
}) => {
  const [show, setShow] = useState(false);
  const [validate, setValidate] = useState(false);
  return (
    <div className="modalPremium__item-outer">
      <div
        className={
          "modalPremium__item " +
          (pass?.[itemData?.name] ? "league" : "") +
          (pos?.[itemData?.name]?.hasPass ? " active" : "") +
          (show ? " show " : "")
        }
        onClick={() => setShow(!show)}
      >
        <div className="modalPremium__item-icon">
          {pass?.[itemData?.name] ? (
            <img src={process.env.PUBLIC_URL + itemData?.image} alt="" />
          ) : itemData?.name === "crypto" ? (
            <img
              className="crypt"
              src={process.env.PUBLIC_URL + itemData?.icon}
              alt=""
            />
          ) : (
            itemData?.icon
          )}
        </div>
        <div className="modalPremium__item-content">
          <h5>{itemData?.title}</h5>
          {pass?.[itemData?.name] ? <p>Spredict Pass</p> : <h6>Free Play</h6>}
        </div>
        {!pass?.[itemData?.name] && (
          <div
            className={
              "check " + (pos?.[itemData?.name]?.hasPass ? "active" : "")
            }
          >
            {checkIcon}
          </div>
        )}
      </div>
      {show && !pass?.[itemData?.name] && (
        <div className="modalPremium__item-action">
          <PassSelect
            list={passList}
            selected={pos?.[itemData?.name]?.select}
            onChange={(data) => {
              setValidate(false);
              updatePos({ [itemData?.name]: { hasPass: false, select: data } });
              if (pos?.[itemData?.name]?.hasPass === true) {
                setCount(count - 1);
              }
            }}
          />
          <div className="modalPremium__item-action-check">
            <div
              className={"check " + (validate ? "active" : "")}
              onClick={() => {
                if (!pass?.[itemData?.name]) {
                  updatePos({
                    [itemData?.name]: {
                      ...pos?.[itemData?.name],
                      hasPass: !pos?.[itemData?.name]?.hasPass,
                      new: true,
                    },
                  });
                  setValidate(!validate);
                  if (pos?.[itemData?.name]?.hasPass === true) {
                    setCount(count - 1);
                  } else {
                    setCount(count + 1);
                  }
                  setShow(false);
                }
              }}
            >
              {checkIcon}
            </div>
            <p>Validate</p>
          </div>
        </div>
      )}
    </div>
  );
};
