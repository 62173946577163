import React, { useState } from "react";
import { CryptoModul } from "../CryptoList";
import ContentDropdown from "../ContentDropdown";

const dropdownList = [
  {
    id: "1",
    title: "ROUND 1 - WEEK 48",
    when: "",
    tooltipText: "ROUND NOT LIVE YET",
  },
  {
    id: "2",
    title: "ROUND 2 - WEEK 49",
    when: "",
    tooltipText: "ROUND NOT LIVE YET",
  },
  {
    id: "3",
    title: "Round 3 - WEEK 50 ",
    when: "",
    tooltipText: "ROUND NOT LIVE YET",
  },
  {
    id: "4",
    title: "Round 4 - WEEK 51",
    when: "",
    tooltipText: "ROUND NOT LIVE YET",
  },
  {
    id: "5",
    title: "SPONSOR POOL",
    when: "",
    tooltipText: "Contact the team to sponsor your pool",
  },
];

export default function LiveEvent({ form, setModal, updateForm }) {
  const [active, setActive] = useState({
    id: "1",
    title: "ROUND 1 - UFC 311",
    when: "",
  });
  return (
    <>
      <div className="content__inst">
        <p className="sm">predict the asset price movement</p>
        <button
          type="button"
          className="content__inst-btn"
          onClick={() => setModal("inst")}
        >
          See full instructions
        </button>
      </div>
      <div className="content__dropdowns">
        {dropdownList?.map((item, index) => {
          return (
            <ContentDropdown
              itemData={item}
              key={index}
              active={active}
              setActive={setActive}
            >
              {index === 0 ? (
                <div className="content__cards">
                  {CryptoModul?.map((item2, index2) => {
                    return (
                      <CardItem
                        key={index2}
                        itemData={item2}
                        form={form}
                        updateForm={updateForm}
                      />
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </ContentDropdown>
          );
        })}
      </div>
    </>
  );
}
const CardItem = ({ itemData, form, updateForm }) => {
  const pickHandler = (cryptoChosen) => {
    let arr = [...form?.round1];
    const index = arr.map((item) => item.id).indexOf(itemData.id);
    if (index === -1) {
      updateForm({
        round1: [
          ...form?.round1,
          {
            id: itemData.id,
            pool: itemData.pool,
            cryptoChosen: cryptoChosen,
          },
        ],
      });
    } else {
      if (form?.round1[index].cryptoChosen.code === cryptoChosen.code) {
        updateForm({
          round1: [...form?.round1?.filter((item) => item.id !== itemData.id)],
        });
      } else {
        arr[index] = {
          ...arr[index],
          cryptoChosen: cryptoChosen,
        };
        updateForm({ round1: arr });
      }
    }
  };
  return (
    <div className="card__outer">
      <div className="card__outer-title">
        <h6>Pool {itemData?.pool}</h6>
      </div>
      <div
        className={
          "card " +
          (form?.round1?.filter((item) => item.id === itemData.id).length > 0
            ? "active"
            : "")
        }
      >
        <div className="card__inner">
          <div className="card__info">
            <h2>
              {itemData?.title} <span>{itemData?.subtitle}</span>
            </h2>
            <div className="card__info-items">
              {itemData?.subtitle && (
                <div className="card__info-item">
                  <p className="sm">{itemData?.subtitle}</p>
                </div>
              )}
              <h6>Pool {itemData?.pool}</h6>
            </div>
          </div>
          <div className="card__row">
            <div
              className={
                "card__item " +
                (form?.round1?.filter((item) => item.id === itemData.id)
                  .length > 0
                  ? form?.round1?.filter((item) => item.id === itemData.id)[0]
                      .cryptoChosen.code === itemData.crypto1.code
                    ? "active"
                    : ""
                  : "")
              }
              onClick={() => pickHandler(itemData.crypto1)}
            >
              <div className="card__item-inner">
                <div className="card__item-row">
                  <h3 className="uniq">{itemData?.crypto1.name}</h3>

                  <div className="card__item-image">
                    <div className="card__item-image-inner">
                      {form?.round1?.filter((item) => item.id === itemData.id)
                        .length > 0 &&
                      form?.round1?.filter((item) => item.id === itemData.id)[0]
                        .cryptoChosen.code === itemData.crypto1.code ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            itemData?.crypto1?.imageActive
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL + itemData?.crypto1?.image
                          }
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "card__item " +
                (form?.round1?.filter((item) => item.id === itemData.id)
                  .length > 0
                  ? form?.round1?.filter((item) => item.id === itemData.id)[0]
                      .cryptoChosen.code === itemData.crypto2.code
                    ? "active"
                    : ""
                  : "")
              }
              onClick={() => pickHandler(itemData.crypto2)}
            >
              <div className="card__item-inner">
                <div className="card__item-row">
                  <h3 className="uniq">{itemData?.crypto2?.name}</h3>
                  <div className="card__item-image">
                    <div className="card__item-image-inner">
                      {form?.round1?.filter((item) => item.id === itemData.id)
                        .length > 0 &&
                      form?.round1?.filter((item) => item.id === itemData.id)[0]
                        .cryptoChosen.code === itemData.crypto2.code ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            itemData?.crypto2?.imageActive
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL + itemData?.crypto2?.image
                          }
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "card__item " +
                (form?.round1?.filter((item) => item.id === itemData.id)
                  .length > 0
                  ? form?.round1?.filter((item) => item.id === itemData.id)[0]
                      .cryptoChosen.code === itemData.crypto3.code
                    ? "active"
                    : ""
                  : "")
              }
              onClick={() => pickHandler(itemData.crypto3)}
            >
              <div className="card__item-inner">
                <div className="card__item-row">
                  <h3 className="uniq">{itemData?.crypto3?.name}</h3>
                  <div className="card__item-image">
                    <div className="card__item-image-inner">
                      {form?.round1?.filter((item) => item.id === itemData.id)
                        .length > 0 &&
                      form?.round1?.filter((item) => item.id === itemData.id)[0]
                        .cryptoChosen.code === itemData.crypto3.code ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            itemData?.crypto3?.imageActive
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL + itemData?.crypto3?.image
                          }
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
