const SideSportsButton = ({isPassAvailable, categoryIcon, categoryTitle})=>{
    return (
    <button type="button" className={"side__btn active " + (isPassAvailable ? "league" : "")}>
        <div className="side__btn-inner">
            <div className="side__btn-icon">{categoryIcon}</div>
            <div className="side__btn-content">
                {isPassAvailable && <h6>PASS</h6>}
                <h5>free play</h5>
                <p>{categoryTitle}</p>
            </div>
        </div>
    </button>
)};

export default SideSportsButton;