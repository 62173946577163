import React from "react";
import { closeIcon, formulaIcon, mmaIcon } from "../SVG";

const list = [
  {
    id: "1",
    title: "Formula 1",
    name: "formula1",
    icon: formulaIcon,
    image: "/images/icons/formulaGoldIcon.svg",
  },
  {
    id: "2",
    title: "MMA",
    name: "mma",
    icon: mmaIcon,
    image: "/images/icons/mmaGoldIcon.svg",
  },
  {
    id: "1",
    title: "Crypto",
    name: "crypto",
    icon: "/images/icons/cryptoIcon.svg",
    image: "/images/icons/cryptoGoldIcon.svg",
  },
];

export default function CongratsModal({
  setModal,
  modalData,
  setModalData,
  updatePass,
}) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalConnect cong">
        <div className="modalConnect__title">
          Congratulations! <br />
          You got the Spredict pass
        </div>
        <div className="modalPremium__items">
          {list?.map((item, index) => {
            if (
              modalData?.[item?.name]?.hasPass &&
              modalData?.[item?.name]?.new
            ) {
              return (
                <PremiumItem
                  itemData={item}
                  key={index}
                  modalData={modalData}
                />
              );
            }
          })}
        </div>
        <div className="modalConnect__content">
          <h5>predict-to-earn </h5>
          <p>
            You are set to enjoy the full experience of Spredict ,compete
            against other players across the globe and earn crypto!
          </p>
        </div>
        <button
          type="button"
          className="modalConnect__btn"
          onClick={() => {
            updatePass({
              formula1: modalData?.formula1?.hasPass,
              mma: modalData?.mma?.hasPass,
              crypto: modalData?.crypto?.hasPass,
            });
            setModalData(null);
            setModal(null);
          }}
        >
          start to earn crypto
        </button>
      </div>
    </>
  );
}
const PremiumItem = ({ itemData }) => {
  return (
    <div className={"modalPremium__item league"}>
      <div className="modalPremium__item-icon">
        <img src={process.env.PUBLIC_URL + itemData?.image} alt="" />
      </div>
      <div className="modalPremium__item-content">
        <h5>{itemData?.title}</h5>
        <p>Spredict Pass</p>
      </div>
    </div>
  );
};
