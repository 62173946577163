import React, { useContext, useState } from "react";
import { FirstContext } from "../../Context/FirstContext";
import PositionedSnackbar from "./PositionedSnackbar";
import SimpleBackdrop from "./SimpleBackdrop";
import NextGP from "./Tabs/NextGP";
import Historical from "./Tabs/Historical";
import { RaceModul } from "../RaceModul";
import Countdown from "react-countdown";
import Leaderboard from "./Tabs/Leaderboard";

export default function Content({
  pass,
  setModal,
  form,
  updateForm,
  race,
  tab,
  setTab,
}) {
  const {
    openMessage,
    setOpenMessage,
    openSuccessMessage,
    setOpenSuccessMessage,
    openBackdrop,
    setOpenBackdrop,
    openErrorMessage,
    setOpenErrorMessage,
    activeButton,
    alreadyPlayed,
    start,
    end,
    bet,
  } = useContext(FirstContext);
  const [isMonthly, setIsMonthly] = useState(true);
  const [tooltip3, setTooltip3] = useState(false);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div
      className={
        "content " +
        (form?.[race?.round]?.length === 10 ? "" : "pb") +
        (tab !== "live-event" ? " fullw" : "")
      }
    >
      <div className="content__inner">
        <div className="content__circ">
          <div className={"content__head " + (pass?.formula1 ? "league" : "")}>
            <div
              className={
                "content__head-title " + (pass?.formula1 ? "league" : "")
              }
            >
              <h2 className="uniq">Clash of Champions</h2>
              {pass?.formula1 ? (
                <div className="content__head-pass">
                  <h5>spredict pass</h5>
                </div>
              ) : (
                <div className="content__head-game">
                  <div className="content__head-game-inner">GAME 1</div>
                </div>
              )}
            </div>
            {tab !== "leaderboard" ? (
              <div className="content__head-date">
                <h5>{race?.when}</h5>
                <h5 className="light">{new Date().getFullYear()}</h5>
              </div>
            ) : isMonthly ? (
              <div className="content__head-date">
                <h5>{month[new Date().getMonth()]}</h5>
                <h5 className="light">{new Date().getFullYear()}</h5>
              </div>
            ) : (
              <div className="content__head-date">
                <h5>Season</h5>
                <h5 className="light">{new Date().getFullYear()} </h5>
              </div>
            )}
          </div>
          <div
            className={"content__circuit " + (pass?.formula1 ? "league" : "")}
          >
            <div className="content__circuit-inner">
              <div className="content__circuit-info">
                <div className="content__circuit-info-wrapper">
                  <div className="content__circuit-info-image">
                    <div className="content__circuit-info-image-inner">
                      {tab === "leaderboard" ? (
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/predict.svg"
                          }
                          alt="flag"
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + race?.flag}
                          alt="flag"
                        />
                      )}
                    </div>
                  </div>
                  <div className="content__circuit-info-text">
                    <h1>
                      {tab === "leaderboard" ? "predict-to-earn" : race?.where}
                    </h1>
                    <p
                      className={"sm " + (tab !== "live-event" ? "active" : "")}
                    >
                      {tab === "live-event" && (
                        <>FORMULA 1 {race.info_circuit} GRAND PRIX 2025</>
                      )}
                      {tab === "historical" && (
                        <>
                          {pass?.formula1 ? "PREDICT-TO-EARN" : "free-to-play"}
                        </>
                      )}
                      {tab === "leaderboard" && (
                        <>
                          {isMonthly
                            ? "monthly championship"
                            : "seasonal championship"}
                        </>
                      )}
                    </p>
                    {tab === "live-event" && (
                      <div
                        className="content__circuit-info-text-about"
                        onClick={() => setModal("info")}
                      >
                        About the phase ?
                      </div>
                    )}
                  </div>
                </div>

                <div className="content__circuit-info-round extra">
                  <div className="content__circuit-info-round-message">
                    {form?.[race?.round]?.length === 10 ? (
                      <h6>
                        {" "}
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/status.svg"
                          }
                          alt=""
                        />
                        Relax! Your prediction is validated
                      </h6>
                    ) : (
                      <>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/moon.svg"
                          }
                          alt=""
                        />
                        <p>
                          ONLY{" "}
                          <span>
                            {start > 0 ? (
                              <Countdown date={start * 1000} />
                            ) : (
                              <Countdown
                                date={
                                  new Date(
                                    new Date().getTime() +
                                      5 * 24 * 60 * 60 * 1000
                                  )
                                }
                              />
                            )}
                          </span>{" "}
                          <br />
                          LEFT TO PLAY
                        </p>
                      </>
                    )}
                  </div>
                  <div className="content__circuit-info-round-text">
                    {tab === "leaderboard" ? (
                      <>
                        <p className="sm">Live</p>
                        <p className="sm">EVENT</p>
                      </>
                    ) : (
                      <>
                        <p className="sm">Round</p>
                        <h6 className="xsm">
                          {RaceModul?.map((item) => item.id).indexOf(race.id) +
                            1}
                          /24
                        </h6>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content__btns">
            <button
              type="button"
              className={
                "content__btn " + (tab === "live-event" ? "active" : "")
              }
              onClick={() => setTab("live-event")}
            >
              <div className="content__btn-inner">Live event</div>
            </button>
            <button
              type="button"
              className={
                "content__btn " + (tab === "historical" ? "active" : "")
              }
              onClick={() => setTab("historical")}
            >
              <div className="content__btn-inner">Past Results</div>
            </button>
            <button
              type="button"
              className={
                "content__btn " + (tab === "leaderboard" ? "active" : "")
              }
              onClick={() => {
                if (pass?.formula1) {
                  setTab("leaderboard");
                } else {
                  setTooltip3(true);
                  setTimeout(() => {
                    setTooltip3(false);
                  }, 2000);
                }
              }}
            >
              <div className="content__btn-inner">
                Leaderboard
                {!pass?.formula1 && (
                  <div className={"tooltip " + (tooltip3 ? "active" : "")}>
                    <p>
                      You must register to the Spredict Pass to access the
                      leaderboard
                    </p>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>

        {tab === "live-event" && (
          <NextGP
            setModal={setModal}
            updateForm={updateForm}
            race={race}
            form={form}
          />
        )}
        {tab === "historical" && <Historical />}
        {tab === "leaderboard" && (
          <Leaderboard setIsMonthly={setIsMonthly} isMonthly={isMonthly} />
        )}
      </div>
      {form?.[race?.round]?.length === 10 && tab === "live-event" && (
        <div className="content__validate">
          <p>
            You have completed <strong>10 steps out of 10 !</strong>
          </p>
          <div onClick={() => bet()} className="content__validate-btn">
            Validate your prediction
          </div>
        </div>
      )}

      <PositionedSnackbar
        open={openMessage}
        setOpen={setOpenMessage}
        message="WRONG NETWORK"
        vertical="top"
        horizontal="center"
        success={false}
      />

      <PositionedSnackbar
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
        message="SUCCESS"
        vertical="top"
        horizontal="center"
        success={true}
      />

      <PositionedSnackbar
        open={openErrorMessage}
        setOpen={setOpenErrorMessage}
        message="ERROR"
        vertical="top"
        horizontal="center"
        success={false}
      />

      <SimpleBackdrop open={openBackdrop} setOpen={setOpenBackdrop} />
    </div>
  );
}
