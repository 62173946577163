const SideGetSpredictPass = ({setModal}) => {
    return (
        <div className="side__pass" onClick={() => setModal("premium")}>
            <div className="side__pass-inner">
                <h5>Get your </h5>
                <h6>Spredict pass</h6>
            </div>
            <div className="side__pass-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/chevronRight.svg"} alt=""/>
            </div>
        </div>
    );
}

export default SideGetSpredictPass;
    