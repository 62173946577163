import React, { useEffect, useState } from "react";
import {
  cancelIcon,
  expandIcon,
  tickIcon,
  userIcon,
} from "../../SVG";
import { AnimatePresence, motion } from "framer-motion";
import { CryptoModul } from "../CryptoList";

const list = [
  {
    id: "1",
    title: (
      <>
        <b>week 48 -</b> round 1
      </>
    ),
    eventType: "",
  },
  {
    id: "2",
    title: (
      <>
        <b>week 49 -</b> round 2
      </>
    ),
    round: "round1",
    eventType: "",
  },
  {
    id: "3",
    title: (
      <>
        <b>WEEk 50 -</b> round 3
      </>
    ),
    eventType: "",
  },
  {
    id: "4",
    title: (
      <>
        <b>WEEk 51 -</b> round 4
      </>
    ),
    eventType: "",
  },
  {
    id: "5",
    title: (
      <>
        <b>WEEk 52 -</b> round 5
      </>
    ),
    eventType: "",
  },
  {
    id: "6",
    title: (
      <>
        <b>WEEk 53 -</b> round 6
      </>
    ),
    eventType: "",
  },
  {
    id: "7",
    title: (
      <>
        sponsor pool <b>- xxxxxxxx</b>
      </>
    ),
    addClass: "sponsor",
    eventType: "",
  },
  {
    id: "8",
    title: (
      <>
        <b>WEEk 5 -</b> round 7
      </>
    ),
    eventType: "",
  },
];

export default function Historical() {
  const [resultsModul, setResultsModul] = useState({
    round1: [
      {
        id: "1",
        pool: "A",
        true_false: false,
        selected_crypto: 3,
      },
      {
        id: "2",
        pool: "B",
        true_false: true,
        selected_crypto: 1,
      },
      {
        id: "3",
        pool: "C",
        true_false: false,
        selected_crypto: null,
      },
    ],
  });
  const [active, setActive] = useState(null);

  return (
    <div className="content__history">
      <div className="content__history-items">
        {list?.map((item, index) => {
          return (
            <ResultItem
              itemData={item}
              key={index}
              resultsModul={resultsModul}
              setActive={setActive}
              active={active}
            />
          );
        })}
      </div>
    </div>
  );
}
const ResultItem = ({ itemData, resultsModul, setActive, active }) => {
  const [points, setPoints] = useState(0);
  useEffect(() => {
    let points = 0;
    resultsModul?.[itemData?.round]?.map((item) => {
      if (item?.true_false === true) {
        points = points + 1;
      }
    });
    setPoints(points);
  }, []);

  return (
    <div
      className={
        "content__history-item " + (active === itemData?.id ? "active" : "")
      }
      onClick={() => {
        if (active === itemData?.id) {
          setActive(null);
        } else {
          setActive(itemData?.id);
        }
      }}
    >
      <div
        className={
          "content__history-item-title " +
          (active === itemData?.id ? "active" : "") +
          (itemData?.addClass ? ` ${itemData?.addClass} ` : "")
        }
      >
        <h6>
          {itemData?.title}{" "}
          {itemData?.eventType && <span>{itemData?.eventType}</span>}
        </h6>
        <div className="content__history-item-title-info">
          <h5>{points < 10 ? `0${points}` : points}</h5>
          <img
            src={process.env.PUBLIC_URL + "/images/icons/tickIconGreen.svg"}
            alt=""
          />
          <span></span>
          <h5>Points</h5>
        </div>
        {expandIcon}
      </div>
      <AnimatePresence>
        {active === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 10 }}
            className={`content__history-item-content  ${
              active ? "active" : ""
            } `}
          >
            {CryptoModul?.map((item, index) => {
              const resultInfo = resultsModul?.[itemData?.round]?.filter(
                (filterItem) => filterItem?.pool === item?.pool
              )[0];
              return (
                <div className="content__history-item-row" key={index}>
                  <h4>{item?.subtitle2}</h4>
                  <div className="content__history-item-row-info">
                    <div className="content__history-item-row-info-icon">
                      {userIcon}
                    </div>
                    <div className="content__history-item-row-info-box">
                      <h6>
                        {resultInfo ? (
                          resultInfo?.selected_crypto === 1 ? (
                            <>
                              {item?.subtitle2} - {item?.crypto1?.name}
                            </>
                          ) : resultInfo?.selected_crypto === 2 ? (
                            <>
                              {item?.subtitle2} - {item?.crypto2?.name}
                            </>
                          ) : resultInfo?.selected_crypto === 3 ? (
                            <>
                              {item?.subtitle2} - {item?.crypto3?.name}
                            </>
                          ) : (
                            <>{item?.subtitle2} - -</>
                          )
                        ) : (
                          "-"
                        )}
                      </h6>
                      {resultInfo?.true_false === true ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/tickIconGreen.svg"
                          }
                        />
                      ) : resultInfo?.true_false === false ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/closeIconGreen.svg"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <h5>{item?.teamNameSm}</h5> */}
                  <div className="content__history-item-row-body">
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_crypto === 1 ? "selected" : "")
                      }
                    >
                      <div className="content__history-item-row-body-item-icon">
                        {userIcon}
                      </div>
                      <h6>{item?.crypto1?.name}</h6>
                    </div>
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_crypto === 2 ? "selected" : "")
                      }
                    >
                      <div className="content__history-item-row-body-item-icon">
                        {userIcon}
                      </div>
                      <h6>{item?.crypto2?.name}</h6>
                    </div>
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_crypto === 3 ? "selected" : "")
                      }
                    >
                      <div className="content__history-item-row-body-item-icon">
                        {userIcon}
                      </div>
                      <h6>{item?.crypto3?.name}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
