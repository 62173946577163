import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FirstContext } from "../Context/FirstContext";

export default function Header({
  menu,
  setMenu,
  setModal,
  connected,
  setConnected,
}) {
  const { connectWallet, disconnectWallet, account, shortedAddress } =
    useContext(FirstContext);
  const { pathname } = useLocation();
  const [tooltip, setTooltip] = useState(false);
  return (
    <header
      className={
        "header " +
        (menu ? "active" : "") +
        (pathname === "/crypto" ? " crypto " : "")
      }
    >
      <div className="header__inner">
        <div className="header__inner-btns">
          <Link
            to={"/defi"}
            className={
              "header__inner-btn " + (pathname === "/defi" ? " active" : "")
            }
            onClick={(e) => {
              e.preventDefault();
              setTooltip(true);
              setTimeout(() => {
                setTooltip(false);
              }, 2000);
            }}
          >
            DeFi
            <div className={"tooltip " + (tooltip ? "active" : "")}>
              <p>Work in progress</p>
            </div>
          </Link>
          <Link
            to={pathname}
            className={
              "header__inner-btn " + (pathname === "/defi" ? "" : "active")
            }
          >
            Play
          </Link>
        </div>
        <Link to={"/"} className="header__inner-logo">
          <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
        </Link>
        {account ? (
          <div className="header__inner-extra">
            <div className="header__inner-extra-text">
              <p>{shortedAddress(account)}</p>
            </div>
            <button
              type="button"
              className="header__inner-connect"
              onClick={() =>  disconnectWallet()}
            >
              <div className="header__inner-connect-inner">Disconnect</div>
            </button>
          </div>
        ) : (
          <div className="header__inner-more">
            <button
              type="button"
              className="header__inner-started"
              onClick={() => {
                if (pathname === "/defi") {
                  setModal("defiStarted");
                } else {
                  setModal("started");
                }
              }}
            >
              Getting started ?
            </button>
            <button
              type="button"
              className="header__inner-connect"
              onClick={() => connectWallet()}
            >
              <div className="header__inner-connect-inner">
                Connect my wallet
              </div>
            </button>
          </div>
        )}

        <div
          className={"burger " + (menu ? "active" : "")}
          id="menuBtn"
          onClick={() => {
            setMenu(!menu);
          }}
        >
          <span></span>
        </div>
      </div>
    </header>
  );
}
