import React, { useState } from "react";
import Countdown from "react-countdown";
// import { mmaIcon } from "../SVG";
import LiveEvent from "./Tabs/LiveEvent";
import Leaderboard from "./Tabs/Leaderboard";
import Historical from "./Tabs/Historical";

export default function Content({
  pass,
  setModal,
  form,
  updateForm,
  tab,
  setTab,
}) {
  const [isMonthly, setIsMonthly] = useState(true);
  const [tooltip3, setTooltip3] = useState(false);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div
      className={
        "content mma crypto " +
        (form?.round1?.length === 3 ? "" : "pb") +
        (tab !== "live-event" ? " fullw" : "")
      }
    >
      <div className="content__inner">
        <div className="content__circ">
          <div className={"content__head " + (pass?.crypto ? "league" : "")}>
            <div
              className={
                "content__head-title " + (pass?.crypto ? "league" : "")
              }
            >
              <h2 className="uniq">
                {tab === "live-event" && "The Predictooor"}
                {tab === "historical" &&
                  (pass?.crypto ? "Crypto" : "The Predictooor")}
                {tab === "leaderboard" && "Crypto"}
              </h2>
              {pass?.crypto ? (
                <div className="content__head-pass">
                  <h5>spredict pass</h5>
                </div>
              ) : (
                <div className="content__head-game">
                  <div className="content__head-game-inner">GAME 1</div>
                </div>
              )}
            </div>
            {tab !== "leaderboard" ? (
              <div className="content__head-date">
                <h5>WEEK 49</h5>
                {/* <h5 className="light">UFC310</h5> */}
              </div>
            ) : isMonthly ? (
              <div className="content__head-date">
                <h5>{month[new Date().getMonth()]}</h5>
                <h5 className="light">{new Date().getFullYear()}</h5>
              </div>
            ) : (
              <div className="content__head-date">
                <h5>Season</h5>
                <h5 className="light">{new Date().getFullYear()} </h5>
              </div>
            )}
          </div>
          <div className={"content__circuit " + (pass?.crypto ? "league" : "")}>
            <div className="content__circuit-inner">
              <div className="content__circuit-info">
                <div className="content__circuit-info-wrapper">
                  <div className="content__circuit-info-image">
                    <div className="content__circuit-info-image-inner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/cryptoIcon2.svg"
                        }
                        alt="flag"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      "content__circuit-info-text " +
                      (pass?.crypto ? "league" : "")
                    }
                  >
                    <h1>
                      {tab === "live-event" && (
                        <>
                          Crypto <span>free-to-play</span>
                        </>
                      )}
                      {tab === "historical" &&
                        (pass?.crypto ? (
                          "predict-to-earn"
                        ) : (
                          <>
                            Crypto <span>free-to-play</span>
                          </>
                        ))}
                      {tab === "leaderboard" && "predict-to-earn"}
                    </h1>
                    <p
                      className={
                        "sm " + (tab === "leaderboard" ? "active" : "")
                      }
                    >
                      {tab === "leaderboard" && (
                        <>
                          {isMonthly
                            ? "monthly championship"
                            : "seasonal championship"}
                        </>
                      )}
                      {tab === "live-event" && (
                        <>{pass?.crypto ? "PREDICT-TO-EARN" : "free-to-play"}</>
                      )}
                      {tab === "historical" && !pass?.crypto && (
                        <>free-to-play</>
                      )}
                    </p>
                    {tab !== "leaderboard" && (
                      <div
                        className="content__circuit-info-text-about"
                        onClick={() => setModal("liveodds")}
                      >
                        Live Odds?
                      </div>
                    )}
                  </div>
                </div>

                <div className="content__circuit-info-round extra">
                  <div className="content__circuit-info-round-message">
                    {form?.round1?.length === 3 ? (
                      <h6>
                        {" "}
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/status.svg"
                          }
                          alt=""
                        />
                        Relax! Your prediction is validated
                      </h6>
                    ) : (
                      <>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/moon.svg"
                          }
                          alt=""
                        />
                        <p>
                          ONLY{" "}
                          <span>
                            <Countdown
                              date={
                                new Date(
                                  new Date().getTime() + 5 * 24 * 60 * 60 * 1000
                                )
                              }
                            />
                          </span>{" "}
                          <br />
                          LEFT TO PLAY
                        </p>
                      </>
                    )}
                  </div>
                  <div className="content__circuit-info-round-text">
                    <p className="sm">Round 1</p>
                    <h6 className="xsm">week 49</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content__btns">
            <button
              type="button"
              className={
                "content__btn " + (tab === "live-event" ? "active" : "")
              }
              onClick={() => setTab("live-event")}
            >
              <div className="content__btn-inner">LIVE EVENT</div>
            </button>
            <button
              type="button"
              className={
                "content__btn " + (tab === "historical" ? "active" : "")
              }
              onClick={() => setTab("historical")}
            >
              <div className="content__btn-inner">Past Results</div>
            </button>
            <button
              type="button"
              className={
                "content__btn " + (tab === "leaderboard" ? "active" : "")
              }
              onClick={() => {
                if (pass?.crypto) {
                  setTab("leaderboard");
                } else {
                  setTooltip3(true);
                  setTimeout(() => {
                    setTooltip3(false);
                  }, 2000);
                }
              }}
            >
              <div className="content__btn-inner">
                Leaderboard
                {!pass?.crypto && (
                  <div className={"tooltip " + (tooltip3 ? "active" : "")}>
                    <p>
                      You must register to the Spredict Pass to access the
                      leaderboard
                    </p>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>

        {tab === "live-event" && (
          <LiveEvent setModal={setModal} updateForm={updateForm} form={form} />
        )}
        {tab === "historical" && <Historical />}
        {tab === "leaderboard" && (
          <Leaderboard setIsMonthly={setIsMonthly} isMonthly={isMonthly} />
        )}
        <div className="content__footer">
          <p>
            MADE BY SPREDICT WITH passion <br /> Copyright © 2025 All rights
            reserved.
          </p>
        </div>
      </div>

      {form?.round1?.length === 3 && tab === "live-event" && (
        <div className="content__validate">
          <p>
            You have completed <strong>3 steps out of 3!</strong>
          </p>
          <div className="content__validate-btn">Validate your prediction</div>
        </div>
      )}
    </div>
  );
}
