
import React from "react";


export default function ContentsCardItem({ 
    sentence2, 
    index, 
    itemData, 
    form, 
    updateForm, 
    categoryData, 
    active,
    activePlayed,
    playersList
}) {

        const handleUpdateForm = choice => {
            if(categoryData.length === 0) return;
            if(active < 0) return;
            if(active >= categoryData.length) return;
            if(activePlayed) return;

            const arr = new Array(parseInt(categoryData[active][3]));
            for(let i = 0; i < parseInt(categoryData[active][3]); i++) {
                if(form[active] && form[active].length > i ) {
                    if(i === index && form[active][i] === choice) {
                        arr[i] = undefined;
                    } else if(i === index){
                        arr[i] = choice;
                    } else {
                        arr[i] = form[active][i];
                    }
                } else if (i === index) {
                    arr[i] = choice;
                }
            }
            
            const arr2 = new Array(categoryData.length);
            for(let i = 0; i < categoryData.length; i++) {
                if(i === active) {
                    arr2[i] = arr;
                } else if(form[i]){
                    arr2[i] = form[i];                
                } 
            }

            updateForm(arr2);
        }


      
    // const pickHandler = (fighterChosen, true_false) => {
    //   let arr = [...form?.round1];
    //   const index = arr.map((item) => item.id).indexOf(itemData.id);
    //   if (index === -1) {
    //     updateForm({
    //       round1: [
    //         ...form?.round1,
    //         {
    //           id: itemData.id,
    //           pool: itemData.pool,
    //           fighterChosen: fighterChosen,
    //           true_false: true_false,
    //         },
    //       ],
    //     });
    //   } elseconst {
    //     if (form?.round1[index].fighterChosen.code === fighterChosen.code) {
    //       updateForm({
    //         round1: [...form?.round1?.filter((item) => item.id !== itemData.id)],
    //       });
    //     } else {
    //       arr[index] = {
    //         ...arr[index],
    //         fighterChosen: fighterChosen,
    //         true_false: true_false,
    //       };
    //       updateForm({ round1: arr });
    //     }
    //   }
    // };

    const trueCountIndex = 10;

    const isActive = active < categoryData.length
      && active < playersList.length
      && playersList[active].length > 0
      && index < categoryData[active][trueCountIndex].length
      && playersList[active].length >= parseInt(categoryData[active][trueCountIndex]);

    const trueFavorite = !isActive || index >= categoryData[active][trueCountIndex].length
      || parseInt(categoryData[active][trueCountIndex][index]) * 100 / parseInt(playersList[active].length) === 50
      ? null
      : parseInt(categoryData[active][trueCountIndex][index]) * 100 / parseInt(playersList[active].length) > 50;


    return (
        <div className="card__outer">
            
            <div className="card__outer-title">
                <h6>{sentence2 + itemData?.pool}</h6>
            </div>

            <div className={"card " + (active >= 0 && form[active]?.length > 0 && "active")}>
                <div className="card__inner">
                    <div className="card__info">
                        <h2>{itemData?.weight}</h2>
                        <div className="card__info-items">
                            {itemData?.eventType && (
                                <div className="card__info-item">
                                    <p className="sm">{itemData?.eventType}</p>
                                </div>
                            )}
                            <h6>{sentence2 + itemData?.pool}</h6>
                        </div>
                    </div>
                    <div className="card__row">
                        <div
                            className={"card__item " + ( active >= 0 && form[active]?.length > 0 && form[active][index] === true && "active" )}
                            onClick={() => handleUpdateForm(true)}
                        >
                            <div className="card__item-inner">
                                <div className={"card__item-info " + (trueFavorite === true && "active")}> 
                                    {trueFavorite === true && ( // update with onchain truChoices
                                        <div className="card__item-info-image">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/icons/favorite.svg"
                                                }
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <h3 className="sm">#{itemData?.fighter1.no}</h3>
                                </div>
                                <div className="card__item-row">
                                    <h3 className="uniq">{itemData?.fighter1.name}</h3>

                                    <div className="card__item-flag">
                                        <div className="card__item-flag-inner">
                                            <img
                                                src={`https://flagsapi.com/${itemData?.fighter1?.flag}/shiny/64.png`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card__row-image">
                            <img src={process.env.PUBLIC_URL + "/images/vs.png"} alt="" />
                        </div>

                        <div
                            className={"card__item " + ( active >= 0 && form[active]?.length > 0 && form[active][index] === false && "active" )}
                            onClick={() => handleUpdateForm(false)}
                        >
                            <div className="card__item-inner">
                                <div
                                    className={"card__item-info " + (trueFavorite === false ? "active" : "")}
                                >
                                    {trueFavorite === false && ( // update with onchain truChoices
                                        <div className="card__item-info-image">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/icons/favorite.svg"
                                                }
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <h3 className="sm">#{itemData?.fighter2?.no}</h3>
                                </div>
                                <div className="card__item-row">
                                    <h3 className="uniq">{itemData?.fighter2?.name}</h3>
                                    <div className="card__item-flag">
                                        <div className="card__item-flag-inner">
                                            <img
                                                src={`https://flagsapi.com/${itemData?.fighter2?.flag}/shiny/64.png`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};