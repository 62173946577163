import React, { useState } from "react";
import { closeIcon, statusIcon } from "../SVG";
import CustomSelect from "../CustomSelect";
import { useEffect } from "react";
import { formulaIcon, mmaIcon } from "../SVG";

const selectList = [
  {
    id: "1",
    value: "Ethereum",
    image: "/images/chains/eth.svg",
    balance: "42.00 ETH",
  },
  {
    id: "2",
    value: "Arbritrum",
    image: "/images/chains/arb.svg",
    balance: "42.00 ARB",
  },
  {
    id: "3",
    value: "Polygon",
    image: "/images/chains/pol.svg",
    balance: "42.00 POL",
  },
  {
    id: "4",
    value: "Base",
    image: "/images/chains/base.svg",
    balance: "42.00 BASE",
  },
];

const selectList2 = [
  {
    id: "1",
    value: "Ethereum",
    smallName: "ETH",
    image: "/images/chains/eth.svg",
    balance: "45.00 ETH",
    balance2: 45.0,
  },

  {
    id: "2",
    value: "Polygon",
    smallName: "POL",
    image: "/images/chains/pol.svg",
    balance: "42.00 POL",
    balance2: 42.0,
  },
  {
    id: "3",
    value: "USD Coin",
    smallName: "USDC",
    image: "/images/chains/usd.svg",
    balance: "50.00 USDC",
    balance2: 50.0,
  },
  {
    id: "3",
    value: "Tether",
    smallName: "TRC",
    image: "/images/chains/tether.svg",
    balance: "22.00 TRC",
    balance2: 22.0,
  },
];
const list = [
  {
    id: "1",
    title: "Formula 1",
    name: "formula1",
    icon: formulaIcon,
    image: "/images/icons/formulaGoldIcon.svg",
  },
  {
    id: "2",
    title: "MMA",
    name: "mma",
    icon: mmaIcon,
    image: "/images/icons/mmaGoldIcon.svg",
  },
  {
    id: "1",
    title: "Crypto",
    name: "crypto",
    icon: "/images/icons/cryptoIcon.svg",
    image: "/images/icons/cryptoGoldIcon.svg",
  },
];
export default function PayModal({ setModal, modalData }) {
  const [error, setError] = useState(false);
  const [balance, setBalance] = useState(45.0);
  const [select, setSelect] = useState(selectList2[0]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (balance < 44.43) {
      setError(true);
    } else {
      setError(false);
    }
  }, [balance]);
  useEffect(() => {
    let countNew = 0;
    if (modalData?.formula1?.hasPass && modalData?.formula1?.new) {
      countNew = countNew + 1;
    }
    if (modalData?.mma?.hasPass && modalData?.mma?.new) {
      countNew = countNew + 1;
    }
    if (modalData?.crypto?.hasPass && modalData?.crypto?.new) {
      countNew = countNew + 1;
    }
    setCount(countNew);
  }, [modalData]);
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalPay">
        <div className="modalPay__info">
          <div className="modalPay__info-inner">
            <CustomSelect list={selectList} selected={selectList[0]} />
            <div className="modalPay__info-wallet">
              <div className="modalPay__info-wallet-inner">
                <div className="modalPay__info-wallet-text">
                  0x00...039493985
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalPay__body">
          <div className="modalPay__body-inner">
            <div className="modalPay__body-info">
              <div className="modalPay__body-info-inner">
                <div className="modalPay__body-info-content">
                  <h5>
                    {balance.toFixed(2)} {select?.smallName}
                  </h5>
                  <p>Your balance</p>
                </div>
                <CustomSelect
                  type={"second"}
                  list={selectList2}
                  selected={select}
                  onChange={(data) => {
                    setBalance(data.balance2);
                    setSelect(data);
                  }}
                />
              </div>
            </div>
            <div className="modalPremium__items">
              {list?.map((item, index) => {
                if (
                  modalData?.[item?.name]?.hasPass &&
                  modalData?.[item?.name]?.new
                ) {
                  return (
                    <PremiumItem
                      itemData={item}
                      key={index}
                      modalData={modalData}
                    />
                  );
                }
              })}
            </div>
            {/* <div className="modalPay__card-outer">
              <div className="modalPay__card">
                <div className="modalPay__card-inner">
                  <h5>F1 League</h5>
                </div>
              </div>
            </div> */}
            <div className={"modalPay__body-price " + (error ? "error" : "")}>
              <div className="modalPay__body-price-inner">
                <p>Price: </p>
                <h5>44.43 {select?.smallName}</h5>
              </div>
            </div>
            {error && (
              <div className="modalPay__body-error">
                <p>Fund required invalid</p>
              </div>
            )}
          </div>
        </div>
        <div className="modalPremium__footer">
          {count !== 0 && (
            <div className="modalPremium__footer-content">
              <div className="modalPremium__footer-row">
                <h6>SubTotal</h6>
                <p>
                  {count}x20 {select?.smallName}
                </p>
              </div>
              <div className="modalPremium__footer-total">
                <h6>Total</h6>
                <p>
                  {count * 20} {select?.smallName}
                </p>
              </div>
            </div>
          )}
          <button
            type="button"
            disabled={error}
            className={"modalPremium__btn " + (count !== 0 ? "sm" : "")}
            onClick={() => setModal("congrats")}
          >
            checkout
          </button>
        </div>
        {/* <button
          type="button"
          className="modalPay__btn"
          onClick={() => setModal("congrats")}
          disabled={error}
        >
          Get your league pass
        </button> */}
      </div>
    </>
  );
}

const PremiumItem = ({ itemData }) => {
  return (
    <div className={"modalPremium__item league"}>
      <div className="modalPremium__item-icon">
        <img src={process.env.PUBLIC_URL + itemData?.image} alt="" />
      </div>
      <div className="modalPremium__item-content">
        <h5>{itemData?.title}</h5>
        <p>Spredict Pass</p>
      </div>
    </div>
  );
};
