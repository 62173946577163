const ContentsValidate = ({
  active,
  predCount,
  bet,
  activePlayed,
  form 
}) => (
  <div className="content__validate">
    <p>
      You have completed <strong>{predCount + " steps out of " + predCount + "!"}</strong>
    </p>
    <div onClick={() => bet(active, activePlayed, form[active])} className="content__validate-btn">Validate your prediction</div>
  </div>
);

export default ContentsValidate;