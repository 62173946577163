import React, { useState } from "react";

const leaderboardList = [
  {
    id: "1",
    rank: "#01",
    address: "0x00...6542",
    points: "240 082",
    addClass: "gold",
  },
  {
    id: "2",
    rank: "#02",
    address: "0x00...6542",
    points: "240 082",
    addClass: "silver",
  },
  {
    id: "3",
    rank: "#03",
    address: "0x00...6542",
    points: "240 082",
    addClass: "bronze",
  },
  {
    id: "4",
    rank: "#04",
    address: "0x00...6542",
    points: "240 082",
  },
  {
    id: "5",
    rank: "#05",
    address: "0x00...6542",
    points: "240 082",
  },
  {
    id: "6",
    rank: "#06",
    address: "0x00...6542",
    points: "240 082",
  },
  {
    id: "7",
    rank: "#07",
    address: "0x00...6542",
    points: "240 082",
  },
  {
    id: "8",
    rank: "#08",
    address: "0x00...6542",
    points: "240 082",
  },
  {
    id: "9",
    rank: "#125",
    address: "0x00...6542",
    points: "240 082",
    addClass: "self",
  },
  {
    id: "10",
    rank: "#344",
    address: "0x00...6542",
    points: "240 082",
  },
];

export default function Leaderboard({ setIsMonthly, isMonthly }) {
  const [clicked, setClicked] = useState(null);

  return (
    <div className="content__leader">
      <div className="content__leader-inner">
        <div className="content__leader-top">
          <div className="content__leader-btns">
            <button
              type="button"
              className={"content__leader-btn " + (isMonthly ? "active" : "")}
              onClick={() => setIsMonthly(true)}
            >
              Monthly
            </button>
            <button
              type="button"
              className={"content__leader-btn " + (isMonthly ? "" : "active")}
              onClick={() => setIsMonthly(false)}
            >
              Season
            </button>
          </div>
          <div className="content__leader-rank">
            <h5>my ranking</h5>
            <h6>
              125 <span>/398</span>
            </h6>
          </div>
        </div>

        <div className="content__leader-board">
          <div className="content__leader-head">
            <h5>Rank</h5>
            <h5>Address</h5>
            <h5>points</h5>
          </div>
          <div className="content__leader-items">
            {leaderboardList?.map((item, index) => {
              return (
                <LeaderBoardItem
                  itemData={item}
                  key={index}
                  clicked={clicked}
                  setClicked={setClicked}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
const LeaderBoardItem = ({ itemData, clicked, setClicked }) => {
  return (
    <div
      className={
        "content__leader-item " +
        (itemData?.addClass ? "active " + itemData?.addClass : "") +
        (clicked?.id === itemData?.id ? " clicked " : "")
      }
      onClick={() => {
        if (clicked?.id === itemData?.id) {
          setClicked(null);
        } else {
          setClicked(itemData);
        }
      }}
    >
      <div className="content__leader-item-info">
        {itemData?.addClass === "gold" && (
          <img src={process.env.PUBLIC_URL + "/images/icons/gold.svg"} alt="" />
        )}
        {itemData?.addClass === "silver" && (
          <img
            src={process.env.PUBLIC_URL + "/images/icons/silver.svg"}
            alt=""
          />
        )}
        {itemData?.addClass === "bronze" && (
          <img
            src={process.env.PUBLIC_URL + "/images/icons/bronze.svg"}
            alt=""
          />
        )}
        {itemData?.addClass === "self" && (
          <img src={process.env.PUBLIC_URL + "/images/icons/selfGreen.svg"} alt="" />
        )}
        <h5>{itemData?.rank}</h5>
      </div>
      <p>{itemData?.address}</p>
      <h6>{itemData?.points}</h6>
    </div>
  );
};
