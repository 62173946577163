export const getExtraSideClasses = (menu, passType, type) => {
    let extraClasses = "";
    if(menu) extraClasses += "active";
    if(passType) extraClasses += " league";
    if(type === "crypto") extraClasses += " crypto";
    return extraClasses;
}

export const handleTooltipEvent = (e, setTooltipVoid) => {
    e.preventDefault();
    handleTooltip(setTooltipVoid)
}

export const handleTooltip = (setTooltipVoid) => {
    setTooltipVoid(true);
    setTimeout(() => {
        setTooltipVoid(false);
    }, 2000);
}