export const DAOTestnet = {
    chainId: "0x153e6923d4b",
    blockExplorerUrls: ["https://etherscan.io/"],
    chainName: "SPREDICT",
    iconUrls: [],
    nativeCurrency: {
        name: "SPREDICT",
        symbol: "SPI",
        decimals: 18
    },
    rpcUrls: ["https://rpc-testnet.defiallodds.io/"]
}

export const Localhost = {
    // chainId: "0x7a69",
    chainId: "0x2b74", 
    blockExplorerUrls: ["https://etherscan.io/"],
    chainName: "localhost",
    iconUrls: [],
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    rpcUrls: ["http://127.0.0.1:8585"]
}

export const AbstractTestnet = {
    chainId: "0x2b74", 
    blockExplorerUrls: ["https://sepolia.abscan.org/"],
    chainName: "Abstract Testnet",
    iconUrls: [],
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    rpcUrls: ["https://api.testnet.abs.xyz"]
}

export const EthereumMainnet = {
    chainId: "0x1",
    blockExplorerUrls: ["https://etherscan.io/"],
    chainName: "Ethereum Mainnet",
    iconUrls: ["/images/chains/eth.svg"],
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    rpcUrls: ["https://eth.llamarpc.com", "https://rpc.mevblocker.io", "https://eth-mainnet.public.blastapi.io"]
}

export const ArbitrumOne = {
    chainId: "0xa4b1",
    blockExplorerUrls: ["https://arbiscan.io/"],
    chainName: "Arbitrum One",
    iconUrls: ["/images/chains/arb.svg"],
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    rpcUrls: ["https://arbitrum.llamarpc.com"]
}

export const Base = {
    chainId: "0x2105",
    blockExplorerUrls: ["https://basescan.io/"],
    chainName: "Base",
    iconUrls: ["/images/chains/base.svg"],
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    rpcUrls: ["https://base.llamarpc.com"]
}

export const Polygon = {
    chainId: "0x89",
    blockExplorerUrls: ["https://polyscan.io/"],
    chainName: "Polygon Mainnet",
    iconUrls: ["/images/chains/pol.svg"],
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
    },
    rpcUrls: ["https://polygon.llamarpc.com", "https://rpc.ankr.com/polygon", "https://polygon-bor-rpc.publicnode.com"]
}