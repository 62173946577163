export const MMAModul = [
  {
    id: "1",
    pool: "A",
    weight: "FLYWEIGHT",
    eventType: "main event",
    fighter1: {
      name: "PANTOJA",
      no: "01",
      code: "1",
      flag: "/images/flags/15.png",
      favorite: true,
      probability: "89%",
    },
    fighter2: {
      name: "asakura",
      no: "NR",
      code: "2",
      flag: "/images/flags/20.png",
      favorite: false,
      probability: "11%",
    },
  },
  {
    id: "2",
    pool: "B",
    weight: "WELTERWEIGHT",
    eventType: "",
    fighter1: {
      name: "rakhmonov",
      no: "03",
      code: "3",
      flag: "/images/flags/20.png",
      favorite: true,
      probability: "64%",
    },
    fighter2: {
      name: "ian machado",
      no: "07",
      code: "4",
      flag: "/images/flags/15.png",
      favorite: false,
      probability: "36%",
    },
  },
  {
    id: "3",
    pool: "C",
    weight: "heavyweight",
    eventType: "",
    fighter1: {
      name: "GANE",
      no: "02",
      code: "5",
      flag: "/images/flags/15.png",
      favorite: false,
      probability: "42%",
    },
    fighter2: {
      name: "volkov",
      no: "03",
      code: "6",
      flag: "/images/flags/20.png",
      favorite: true,
      probability: "58%",
    },
  },
  {
    id: "4",
    pool: "D",
    weight: "WELTERWEIGHT",
    eventType: "",
    fighter1: {
      name: "LUQUE",
      no: "14",
      code: "7",
      flag: "/images/flags/20.png",
      favorite: false,
      probability: "31%",
    },
    fighter2: {
      name: "DIAZ",
      no: "NR",
      code: "8",
      flag: "/images/flags/20.png",
      favorite: true,
      probability: "69%",
    },
  },
];
