/* eslint-disable react/jsx-no-target-blank */
const SideSocials = ({ twitterIcon }) => {
    return (
        <div className="info__socials-outer">
            <div className="info__socials">
                <a
                    href="https://twitter.com/spredict_io"
                    target="_blank"
                    className="info__social"
                >
                    {twitterIcon}
                </a>
                <a
                    href="https://spredict.io/"
                    target="_blank"
                    className="info__social"
                >
                    <img
                        src={process.env.PUBLIC_URL + "/images/icons/language.png"}
                        alt=""
                    />
                </a>

                <a
                    href="https://t.me/SpredictOfficialPortal"
                    target="_blank"
                    className="info__social"
                >
                    <img
                        src={process.env.PUBLIC_URL + "/images/icons/telegram.png"}
                        alt=""
                    />
                </a>
            </div>
            <div className="info__socials">
                {/* <a
            href="https://discord.gg/EVuCU2Ubhu"
            target="_blank"
            className="info__social"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/icons/discord.png"}
              alt=""
            />
          </a> */}
                <a
                    href="https://medium.com/@Spredict"
                    target="_blank"
                    className="info__social"
                >
                    <img
                        src={process.env.PUBLIC_URL + "/images/icons/medium.png"}
                        alt=""
                    />
                </a>
            </div>
        </div>
    );
}

export default SideSocials;
