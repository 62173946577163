import { monthNames } from "../../Helpers"

const ContentsDateDisplay = ({ tab, isMonthly }) => {
    return (
        tab !== "leaderboard" ? (
            <div className="content__head-date">
                <h5>WEEK 3</h5>
                <h5 className="light">UFC311</h5>
            </div>
        ) : (
            <div className="content__head-date">
                <>
                    <h5>{isMonthly ? monthNames[new Date().getMonth()] : "Season"}</h5>
                    <h5 className="light">{new Date().getFullYear()}</h5>
                </>
            </div>
        )
    );
};

export default ContentsDateDisplay;