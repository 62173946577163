import ContentsLiveEvent from "./ContentsLiveEvent";
import Historical from "../MMA/Tabs/Historical";
import Leaderboard from "../MMA/Tabs/Leaderboard";

const ContentsTab = ({ 
  sentence1,
  sentence2,
  tab, 
  setModal, 
  form, 
  updateForm,
  categoryData,
  active, 
  setIsMonthly, 
  isMonthly,
  poolData,
  activePlayed,
  playersList
}) => {
  switch (tab) {
    case "live-event":
      return (
        <ContentsLiveEvent
          sentence1={sentence1}
          sentence2={sentence2}
          setModal={setModal}
          updateForm={updateForm}
          form={form}
          categoryData={categoryData}
          active={active}
          poolData={poolData}
          activePlayed={activePlayed}
          playersList={playersList}
        />
      );
    case "historical":
      return <Historical />;
    case "leaderboard":
      return (
        <Leaderboard
          setIsMonthly={setIsMonthly}
          isMonthly={isMonthly}
        />
      );
    default:
      return null;
  }
};

export default ContentsTab;