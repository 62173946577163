import { Link, useLocation } from "react-router-dom";
import { handleTooltipEvent } from "./SideHelpers";

const SideLinks = ({ tooltip, setTooltip }) => {
    const { pathname } = useLocation();

    return (
        <div className="header__inner-btns">
            <Link
                to={"/defi"}
                className={"header__inner-btn " + (pathname === "/defi" ? " active" : "")}
                onClick={e => handleTooltipEvent(e, setTooltip)}
            >
                DeFi
                <div className={"tooltip " + (tooltip ? "active" : "")}>
                    <p>Work in progress</p>
                </div>
            </Link>
            <Link to={pathname} className={"header__inner-btn " + (pathname === "/defi" ? "" : "active")}>Play</Link>
        </div>
    );
}

export default SideLinks;