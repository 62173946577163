const SideUncategorisedButton = ({ handleTooltip, setTooltip3, tooltip3 }) => {
    return (
        <button
            type="button"
            className={"side__rewards"}
            onClick={() => {handleTooltip(setTooltip3)}}
        >
            Rewards
            <div className={"tooltip " + (tooltip3 ? "active" : "")}>
                <p>
                    To earn crypto you must subscribe to a Spredict Pass
                </p>
            </div>
        </button>
    )
};

export default SideUncategorisedButton;