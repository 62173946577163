import React from "react";
import { closeIcon } from "../SVG";

export default function ConnectModal({ setModal }) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalConnect">
        <div className="modalConnect__title">Connect your wallet</div>
        <div className="modalConnect__subtitle">Get your Spredict pass</div>
        <div className="modalConnect__card-outer">
          <div className="modalConnect__card">
            <div className="modalConnect__card-inner">
              <div className="modalConnect__card-inner-blur"></div>
              <h5>SPREDICT PASS</h5>
            </div>
          </div>
        </div>
        <div className="modalConnect__content">
          <h5>predict-to-earn </h5>
          <p>
            By subscribing to the Spredict Pass you’ll be able to earn crypto by
            competing against other players.
          </p>
        </div>
        <button
          type="button"
          className="modalConnect__btn"
          onClick={() => setModal("pay")}
        >
          Connect my wallet
        </button>
      </div>
    </>
  );
}
