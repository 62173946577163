import React, { useEffect, useState } from "react";
import {
  // cancelIcon,
  expandIcon,
  // tickIcon,
  userIcon,
} from "../../SVG";
import { AnimatePresence, motion } from "framer-motion";
// import { RaceModul } from "../../RaceModul";
// import { FormulaModul } from "../../F1/DriverList";
import { MMAModul } from "../FighterList";

const list = [
  {
    id: "1",
    title: (
      <>
        <b>week 47 -</b> UFC309
      </>
    ),
    eventType: "",
  },
  {
    id: "2",
    title: (
      <>
        <b>week 48 -</b> UFC
      </>
    ),
    eventType: "night",
  },
  {
    id: "3",
    title: (
      <>
        <b>WEEk 49 -</b> UFC 311
      </>
    ),
    round: "round1",
    eventType: "",
  },
  {
    id: "4",
    title: (
      <>
        <b>WEEk 50 -</b> UFC
      </>
    ),
    eventType: "night",
  },
  {
    id: "5",
    title: (
      <>
        <b>WEEk 2 -</b> UFC
      </>
    ),
    eventType: "night",
  },
  {
    id: "6",
    title: (
      <>
        <b>WEEk 3 -</b> UFC 311
      </>
    ),
    eventType: "",
  },
  {
    id: "7",
    title: (
      <>
        sponsor pool <b>- xxxxxxxx</b>
      </>
    ),
    addClass: "sponsor",
    eventType: "",
  },
  {
    id: "8",
    title: (
      <>
        <b>WEEk 5 -</b> UFC
      </>
    ),
    eventType: "night",
  },
];

export default function Historical() {
  const [resultsModul, setResultsModul] = useState({
    round1: [
      {
        id: "1",
        pool: "A",
        true_false: true,
        selected_fighter: 1,
      },
      {
        id: "2",
        pool: "B",
        true_false: false,
        selected_fighter: 1,
      },
      {
        id: "3",
        pool: "C",
        true_false: false,
        selected_fighter: 1,
      },
      {
        id: "4",
        pool: "D",
        true_false: false,
        selected_fighter: 1,
      },
    ],
  });
  const [active, setActive] = useState(null);

  return (
    <div className="content__history">
      <div className="content__history-items">
        {list?.map((item, index) => {
          return (
            <ResultItem
              itemData={item}
              key={index}
              resultsModul={resultsModul}
              setActive={setActive}
              active={active}
            />
          );
        })}
      </div>
    </div>
  );
}
const ResultItem = ({ itemData, resultsModul, setActive, active }) => {
  const [points, setPoints] = useState(0);
  useEffect(() => {
    let points = 0;
    resultsModul?.[itemData?.round]?.map((item) => {
      if (item?.true_false === true) {
        points = points + 1;
      }
    });
    setPoints(points);
  }, []);

  return (
    <div
      className={
        "content__history-item " + (active === itemData?.id ? "active" : "")
      }
      onClick={() => {
        if (active === itemData?.id) {
          setActive(null);
        } else {
          setActive(itemData?.id);
        }
      }}
    >
      <div
        className={
          "content__history-item-title " +
          (active === itemData?.id ? "active" : "") +
          (itemData?.addClass ? ` ${itemData?.addClass} ` : "")
        }
      >
        <h6>
          {itemData?.title}{" "}
          {itemData?.eventType && <span>{itemData?.eventType}</span>}
        </h6>
        <div className="content__history-item-title-info">
          <h5>{points < 10 ? `0${points}` : points}</h5>
          <img
            src={process.env.PUBLIC_URL + "/images/icons/tickIcon.svg"}
            alt=""
          />
          <span></span>
          <h5>Points</h5>
        </div>
        {expandIcon}
      </div>
      <AnimatePresence>
        {active === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 10 }}
            className={`content__history-item-content  ${
              active ? "active" : ""
            } `}
          >
            {MMAModul?.map((item, index) => {
              const resultInfo = resultsModul?.[itemData?.round]?.filter(
                (filterItem) => filterItem?.pool === item?.pool
              )[0];
              return (
                <div className="content__history-item-row" key={index}>
                  <h4>Pool {item?.pool}</h4>
                  <div className="content__history-item-row-info">
                    <div className="content__history-item-row-info-icon">
                      {userIcon}
                    </div>
                    <div className="content__history-item-row-info-box">
                      <h6>
                        {resultInfo
                          ? resultInfo?.selected_fighter === 1
                            ? item?.fighter1?.name
                            : item?.fighter2?.name
                          : "-"}
                      </h6>
                      {resultInfo?.true_false === true ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/tickIcon.svg"
                          }
                        />
                      ) : resultInfo?.true_false === false ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/closeIcon.svg"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <h5>{item?.teamNameSm}</h5> */}
                  <div className="content__history-item-row-body">
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_fighter === 1 &&
                        resultInfo?.true_false === true
                          ? "selected"
                          : resultInfo?.selected_fighter === 2 &&
                            resultInfo?.true_false === false
                          ? "rightone"
                          : "")
                      }
                    >
                      <h6>{item?.fighter1?.name}</h6>
                    </div>
                    <div className="content__history-item-row-body-image">
                      <img
                        src={process.env.PUBLIC_URL + "/images/vs.png"}
                        alt=""
                      />
                    </div>
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_fighter === 2 &&
                        resultInfo?.true_false === true
                          ? "selected"
                          : resultInfo?.selected_fighter === 1 &&
                            resultInfo?.true_false === false
                          ? "rightone"
                          : "")
                      }
                    >
                      <h6>{item?.fighter2?.name}</h6>
                    </div>
                  </div>
                  {/* <div
                    className={
                      "content__history-item-icon " +
                      (resultInfo?.true_false ? "selected" : "")
                    }
                  >
                    {resultInfo?.true_false === true
                      ? tickIcon
                      : resultInfo?.true_false === false
                      ? cancelIcon
                      : ""}
                  </div> */}
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
