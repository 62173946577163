import Countdown from "react-countdown";
import { getExtraLeagueClass, getSubLabel } from "../Helpers";
import TabLabel from "./ContentsTabLabel";

const ContentsInfo = ({ 
   tab, 
   isPassForCategory, 
   isMonthly, 
   setModal, 
   categoryName, 
   categoryIcon, 
   categoryPredCount, 
   userPredCount,
   activePlayed,
   categoryData,
   active
}) => {
   // const round1Countdown = new Date(
   //    new Date().getTime() + 5 * 24 * 60 * 60 * 1000
   // );

   return (
      <div className={"content__circuit " + getExtraLeagueClass(isPassForCategory)}>
         <div className="content__circuit-inner">
            <div className="content__circuit-info">
               <div className="content__circuit-info-wrapper">
                  <div className="content__circuit-info-image">
                     <div className="content__circuit-info-image-inner">
                        {categoryIcon}
                     </div>
                  </div>
                  <div
                     className={
                        "content__circuit-info-text " + getExtraLeagueClass(isPassForCategory)
                     }
                  >
                     <h1>
                        <TabLabel tab={tab} isPassForCategory={isPassForCategory} categoryName={categoryName} />
                     </h1>
                     <p className={"sm " + (tab === "leaderboard" ? "active" : "")}>
                        {getSubLabel(tab, isMonthly, isPassForCategory)}
                     </p>
                     {tab !== "leaderboard" && (
                        <div
                           className="content__circuit-info-text-about"
                           onClick={() => setModal("liveodds")}
                        >
                           Live Odds?
                        </div>
                     )}
                  </div>
               </div>
               <div className="content__circuit-info-round extra">
                  <div className="content__circuit-info-round extra">
                     <div className="content__circuit-info-round-message">
                        {activePlayed ? (
                           <h6>
                              {" "}
                              <img
                                 src={
                                    process.env.PUBLIC_URL + "/images/icons/status.svg"
                                 }
                                 alt=""
                              />
                              Relax! Your prediction is validated
                           </h6>
                        ) : (
                           <>
                              <img
                                 src={
                                    process.env.PUBLIC_URL + "/images/icons/moon.svg"
                                 }
                                 alt=""
                              />
                              <p>
                                 ONLY{" "}
                                 <span>
                                    <Countdown
                                       date={
                                          active >= 0 && active < categoryData?.length 
                                             ? parseInt(categoryData[active][1]) * 1000

                                             : new Date(
                                                new Date().getTime() + 5 * 24 * 60 * 60 * 1000
                                             )
                                       }
                                    />
                                 </span>{" "}
                                 <br />
                                 LEFT TO PLAY
                              </p>
                           </>
                        )}
                     </div>
                     <div className="content__circuit-info-round-text">
                        <p className="sm">Round 1</p>
                        <h6 className="xsm">1/4</h6>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
  )};

         export default ContentsInfo;