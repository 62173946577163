import ContentsValidate from "./Contents/ContentsValidate";

export const getTitle = (tab, isPassForCategory, categoryName) => {
    switch (tab) {
        case "live-event":
            return "Face Off";
        case "historical":
            return isPassForCategory ? categoryName : "Face Off";
        case "leaderboard":
            return categoryName;
        default:
            return "";
    }
};

export const getExtraLeagueClass = isPassForCategory => {
    if (isPassForCategory) return "league";
    else return "";
};

export const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const getContentExtraClasses = (form, tab, predCount) => {
    let extraClasses = "";

    if (form?.round1?.length !== predCount) {
        extraClasses += " pb";
    }

    if (tab !== "live-event") {
        extraClasses += " fullw";
    }

    return extraClasses.trim();
};

export const getSubLabel = (tab, isMonthly, isPassForCategory) => {
    if (tab === "leaderboard") {
        return isMonthly ? "monthly championship" : "seasonal championship";
    } else if (tab === "live-event") {
        return isPassForCategory ? "PREDICT-TO-EARN" : "free-to-play";
    } else if (tab === "historical" && !isPassForCategory) {
        return "free-to-play";
    }
    return null;
};

export const ContentValidateParser = ({form, active, categoryData, activePlayed, tab, predCount, bet}) => { 
    return (
        <>
            {
                active >= 0
                && active < categoryData.length
                && !activePlayed
                && form[active]
                && form[active].length > 0
                && form[active].length === predCount
                && form[active].filter(el => typeof el === "boolean").length === predCount
                && tab === "live-event"

                ? <ContentsValidate
                    active={active}
                    predCount={predCount}
                    bet={bet}
                    activePlayed={activePlayed}
                    form={form}
                />
                : null
            }
        </>
    );
}

export const getExtraCategoryContentsClasses = categoryName => {
    let extraClasses = "";

    if (categoryName === "MMA") {
        extraClasses += "mma ";
    }

    if (categoryName === "CRYPTO") {
        extraClasses += "mma crypto ";
    }

    return extraClasses;
}