import React, { useEffect, useRef, useState } from "react";
import { chevronBottom, chevronBottom2, chevronBottom3 } from "./SVG";

const CustomSelect = ({
  selected = null,
  list,
  type = null,
  error = null,
  value = null,
  onChange,
}) => {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(selected);

  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  return (
    <div className={`customSelect ${active ? "active " : ""}`} ref={wrapper}>
      <div className="customSelect__selected" onClick={toggleActive}>
        {type === "second" ? (
          <img src={process.env.PUBLIC_URL + currentSelected?.image} alt="" />
        ) : (
          <div className="customSelect__status">
            <span></span>
          </div>
        )}
        {currentSelected ? (
          type === "second" ? (
            <p>{currentSelected?.smallName}</p>
          ) : (
            <p>{currentSelected.value}</p>
          )
        ) : (
          "Choose"
        )}
        {type === "second" ? chevronBottom3 : chevronBottom2}
      </div>
      <div className="customSelect__options active">
        <div className="customSelect__options-inner">
          {currentList.map((item, index) => (
            <div
              className={
                "customSelect__options-item " +
                (currentSelected?.value === item?.value ? "active" : "")
              }
              key={index}
              onClick={() => onClick(item)}
            >
              <div className="customSelect__options-item-inner">
                <div className="customSelect__options-item-info">
                  <img src={process.env.PUBLIC_URL + item.image} alt="" />
                  <h5>{item.value}</h5>
                </div>
                <div className="customSelect__options-item-extra">
                  {currentSelected?.value === item?.value ? (
                    <div className="customSelect__status">
                      <span></span>
                    </div>
                  ) : (
                    <>
                      <h5>{item?.balance}</h5>
                      <p>Balance</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
