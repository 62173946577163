const SideDisconnected = ({isDefi, setModal, connectWallet}) => {
    return (
        <>
        <button
          type="button"
          className="header__inner-started"
          onClick={() => {
            if (isDefi) {
              setModal("defiStarted");
            } else {
              setModal("started");
            }
          }}
        >
          Getting started ?
        </button>
        <button
          type="button"
          className="header__inner-connect"
          onClick={() => connectWallet()}
        >
          <div className="header__inner-connect-inner">
            Connect my wallet
          </div>
        </button>
      </>
    );
}

export default SideDisconnected;