export const CryptoModul = [
  {
    id: "1",
    pool: "A",
    title: "BTC-Bitcoin",
    subtitle: "WEEKLY",
    subtitle2: "BTC WEEKLY",
    crypto1: {
      name: "Bearish",
      code: "1",
      image: "/images/crypto/bearish.png",
      imageActive: "/images/crypto/bearishActive.png",
      imageLive: "/images/crypto/bearishLive.png",
      probability: "86,740",
      operator: "< $",
    },
    crypto2: {
      name: "RANGE",
      code: "2",
      image: "/images/crypto/range.png",
      imageActive: "/images/crypto/rangeActive.png",
      imageLive: "/images/crypto/rangeLive.png",
      probability: "86,740-95,870",
      operator: "$",
    },
    crypto3: {
      name: "Bullish",
      code: "3",
      image: "/images/crypto/bullish.png",
      imageActive: "/images/crypto/bullishActive.png",
      imageLive: "/images/crypto/bullishLive.png",
      probability: "95,870",
      operator: "> $",
    },
  },
  {
    id: "2",
    pool: "B",
    title: "ETH-ethereum",
    subtitle: "WEEKLY",
    subtitle2: "ETH WEEKLY",
    crypto1: {
      name: "Bearish",
      code: "1",
      image: "/images/crypto/bearish.png",
      imageActive: "/images/crypto/bearishActive.png",
      imageLive: "/images/crypto/bearishLive.png",
      probability: "86,740",
      operator: "< $",
    },
    crypto2: {
      name: "RANGE",
      code: "2",
      image: "/images/crypto/range.png",
      imageActive: "/images/crypto/rangeActive.png",
      imageLive: "/images/crypto/rangeLive.png",
      probability: "86,740-95,870",
      operator: "$",
    },
    crypto3: {
      name: "Bullish",
      code: "3",
      image: "/images/crypto/bullish.png",
      imageActive: "/images/crypto/bullishActive.png",
      imageLive: "/images/crypto/bullishLive.png",
      probability: "95,870",
      operator: "> $",
    },
  },
  {
    id: "3",
    pool: "C",
    title: "SOL-Solana",
    subtitle: "WEEKLY",
    subtitle2: "SOL WEEKLY",
    crypto1: {
      name: "Bearish",
      code: "1",
      image: "/images/crypto/bearish.png",
      imageActive: "/images/crypto/bearishActive.png",
      imageLive: "/images/crypto/bearishLive.png",
      probability: "86,740",
      operator: "< $",
    },
    crypto2: {
      name: "RANGE",
      code: "2",
      image: "/images/crypto/range.png",
      imageActive: "/images/crypto/rangeActive.png",
      imageLive: "/images/crypto/rangeLive.png",
      probability: "86,740-95,870",
      operator: "$",
    },
    crypto3: {
      name: "Bullish",
      code: "3",
      image: "/images/crypto/bullish.png",
      imageActive: "/images/crypto/bullishActive.png",
      imageLive: "/images/crypto/bullishLive.png",
      probability: "95,870",
      operator: "> $",
    },
  },
];
