import SideCryptoButton from "./SideCryptoButton";
import SideSportsButton from "./SideSportsButton";

const SideCategoryElement = ({
	isPassAvailable,
	categoryIcon,
	categoryTitle,
	handleTooltip,
	tooltip,
	setTooltip,
	tooltip3,
	setTooltip3,
	setModal,
	secondaryTitle,
	tertiaryTitle,
	passOfType,
	rewards
}) => {

	return <>
		<div className={"side__selected " + (isPassAvailable ? "league" : "")}>
			{
				categoryTitle === "crypto" 
				? <SideCryptoButton 
					isPassAvailable={isPassAvailable}
					categoryTitle={categoryTitle} 
				/> 
				: <SideSportsButton 
					isPassAvailable={isPassAvailable}
					categoryIcon={categoryIcon}
					categoryTitle={categoryTitle} 
				/>
			}
			<div className="side__selected-games">
				<div className={"side__selected-game active"}>
					<div className="side__selected-game-inner">
						<div className="side__selected-game-title">
							{secondaryTitle} {/* Clash of Champions */}
						</div>
						<div className="side__selected-game-subtitle">
							Game 1
						</div>
					</div>
				</div>
				<div className="side__selected-game" onClick={e => handleTooltip(e, setTooltip)}>
					<div className={"tooltip " + (tooltip ? "active" : "")}>
						<p>Work in progress</p>
					</div>
					<div className="side__selected-game-inner">
						<div className="side__selected-game-title">
							{tertiaryTitle} {/* Rival Racers */}
						</div>
						<div className="side__selected-game-subtitle">
							Game 2
						</div>
					</div>
				</div>
			</div>
			{passOfType && (
				<button type="button" className={"side__rewards"}
					onClick={() => {
						if (passOfType && rewards) {
							setModal("tooltip");
						} else {
							handleTooltip(setTooltip3)
						}
					}}
				>
					Rewards
					{!rewards && (
						<div className={"tooltip " + (tooltip3 ? "active" : "")}>
							<p>No rewards so far Play to earn crypto!</p>
						</div>
					)}
				</button>
			)}
		</div>
	</>
};

export default SideCategoryElement;



