/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { MMAModul } from "./FighterList";
import { AnimatePresence, motion } from "framer-motion";
import {
  chevronBottom2,
  closeIcon,
  twitterIcon,
  userIcon,
} from "../SVG";
const roundList = [
  {
    id: "1",
    isActive: true,
  },
  {
    id: "2",
    isActive: false,
  },
  {
    id: "3",
    isActive: false,
  },
  {
    id: "4",
    isActive: false,
  },
];

export default function LiveOddsMod({ setModal, form }) {
  const [active, setActive] = useState(null);
  const [activePred, setActivePred] = useState(null);
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-elem"></div>
      <div className="modalLive">
        <div className="liveodds__title">
          <h5>live odds</h5>
        </div>
        <div className="liveodds__rounds">
          {roundList?.map((item, index) => {
            return (
              <LiveOddsItem
                itemData={item}
                key={index}
                active={active}
                setActive={setActive}
                index={index}
              />
            );
          })}
        </div>
        <div className="info__socials-outer">
          <div className="info__socials">
            <a
              href="https://twitter.com/spredict_io"
              target="_blank"
              className="info__social"
            >
              {twitterIcon}
            </a>
            <a
              href="https://spredict.io/"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/language.png"}
                alt=""
              />
            </a>

            <a
              href="https://t.me/SpredictOfficialPortal"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/telegram.png"}
                alt=""
              />
            </a>
            <a
              href="https://medium.com/@Spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/medium.png"}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="liveodds__predicts">
          <div className="liveodds__predicts-title">
            <h5>Player predictions</h5>
          </div>
          <div className="liveodds__predicts-inner">
            {roundList?.map((item, index) => {
              return (
                <PredictionItem
                  itemData={item}
                  key={index}
                  active={activePred}
                  setActive={setActivePred}
                  index={index}
                  form={form}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
const LiveOddsItem = ({ itemData, index, active, setActive }) => {
  return (
    <div
      className={
        "liveodds__round " + (active?.id === itemData?.id ? "active" : "")
      }
      onClick={() => {
        if (active?.id === itemData?.id) {
          setActive(null);
        } else {
          setActive(itemData);
        }
      }}
    >
      <div className="liveodds__round-title ">
        <h6>Round {index + 1}</h6>
        {chevronBottom2}
      </div>
      <AnimatePresence>
        {active?.id === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="liveodds__round-items"
          >
            {MMAModul?.map((item, index2) => {
              return (
                <div className="liveodds__round-item" key={index2}>
                  <div className="liveodds__round-item-title">
                    <h6>Pool {item?.pool}</h6>
                  </div>
                  <div className="liveodds__round-item-inner">
                    <div
                      className={
                        "liveodds__round-item-info " +
                        (item?.fighter1?.favorite && itemData?.isActive
                          ? "active"
                          : "")
                      }
                    >
                      {item?.fighter1?.favorite && itemData?.isActive && (
                        <div className="liveodds__round-item-info-image">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/favorite.svg"
                            }
                            alt=""
                          />
                        </div>
                      )}
                      <h6>{item?.fighter1?.name}</h6>
                      {itemData?.isActive && (
                        <p>{item?.fighter1?.probability}</p>
                      )}
                    </div>
                    <div
                      className={
                        "liveodds__round-item-info " +
                        (item?.fighter2?.favorite && itemData?.isActive
                          ? "active"
                          : "")
                      }
                    >
                      {item?.fighter2?.favorite && itemData?.isActive && (
                        <div className="liveodds__round-item-info-image">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/favorite.svg"
                            }
                            alt=""
                          />
                        </div>
                      )}
                      <h6>{item?.fighter2?.name}</h6>
                      {itemData?.isActive && (
                        <p>{item?.fighter2?.probability}</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PredictionItem = ({ itemData, index, active, setActive, form }) => {
  return (
    <div
      className={
        "liveodds__predict " + (active?.id === itemData?.id ? "active" : "")
      }
      onClick={() => {
        if (active?.id === itemData?.id) {
          setActive(null);
        } else {
          setActive(itemData);
        }
      }}
    >
      <div className="liveodds__predict-title ">
        <h6>Round {index + 1}</h6>
        {chevronBottom2}
      </div>
      <AnimatePresence>
        {active?.id === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="liveodds__predict-items"
          >
            {MMAModul?.map((item, index2) => {
              return (
                <div className="liveodds__predict-item" key={index2}>
                  <div className="liveodds__predict-item-title">
                    <h6>Pool {item?.pool}</h6>
                  </div>
                  <div className="liveodds__predict-item-inner">
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0
                          ? form?.round1?.filter(
                              (filterItem) => filterItem.id === item.id
                            )[0].fighterChosen.code === item.fighter1.code &&
                            itemData?.isActive
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                        form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        )[0].fighterChosen.code === item.fighter1.code &&
                        itemData?.isActive && (
                          <div className="liveodds__predict-item-info-icon">
                            {userIcon}
                          </div>
                        )}
                      <h6>{item?.fighter1?.name}</h6>
                      {itemData?.isActive && (
                        <p>{item?.fighter1?.probability}</p>
                      )}
                    </div>
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0
                          ? form?.round1?.filter(
                              (filterItem) => filterItem.id === item.id
                            )[0].fighterChosen.code === item.fighter2.code &&
                            itemData?.isActive
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                        form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        )[0].fighterChosen.code === item.fighter2.code &&
                        itemData?.isActive && (
                          <div className="liveodds__predict-item-info-icon">
                            {userIcon}
                          </div>
                        )}
                      <h6>{item?.fighter2?.name}</h6>
                      {itemData?.isActive && (
                        <p>{item?.fighter2?.probability}</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
