import React, { useState } from "react";
import { chevronBottom2, twitterIcon, userIcon } from "../SVG";
import { CryptoModul } from "./CryptoList";
import { AnimatePresence, motion } from "framer-motion";

const roundList = [
  {
    id: "1",
    isActive: true,
  },
  {
    id: "2",
    isActive: false,
  },
  {
    id: "3",
    isActive: false,
  },
  {
    id: "4",
    isActive: false,
  },
];

export default function LiveOdds({ form }) {
  const [active, setActive] = useState(null);
  const [activePred, setActivePred] = useState(null);
  return (
    <div className="liveodds crypto">
      <div className="liveodds__inner">
        <div className="liveodds__title">
          <h5>live odds</h5>
        </div>
        <div className="liveodds__rounds">
          {roundList?.map((item, index) => {
            return (
              <LiveOddsItem
                itemData={item}
                key={index}
                active={active}
                setActive={setActive}
                index={index}
              />
            );
          })}
        </div>
        <div className="info__socials-outer">
          <div className="info__socials">
            <a
              href="https://twitter.com/spredict_io"
              target="_blank"
              className="info__social"
            >
              {twitterIcon}
            </a>
            <a
              href="https://spredict.io/"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/language.png"}
                alt=""
              />
            </a>

            <a
              href="https://t.me/SpredictOfficialPortal"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/telegram.png"}
                alt=""
              />
            </a>
            <a
              href="https://medium.com/@Spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/medium.png"}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="liveodds__predicts">
          <div className="liveodds__predicts-title">
            <h5>Player predictions</h5>
          </div>
          <div className="liveodds__predicts-inner">
            {roundList?.map((item, index) => {
              return (
                <PredictionItem
                  itemData={item}
                  key={index}
                  active={activePred}
                  setActive={setActivePred}
                  index={index}
                  form={form}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const LiveOddsItem = ({ itemData, index, active, setActive }) => {
  return (
    <div
      className={
        "liveodds__round " + (active?.id === itemData?.id ? "active" : "")
      }
      onClick={() => {
        if (active?.id === itemData?.id) {
          setActive(null);
        } else {
          setActive(itemData);
        }
      }}
    >
      <div className="liveodds__round-title ">
        <h6>Round {index + 1}</h6>
        {chevronBottom2}
      </div>
      <AnimatePresence>
        {active?.id === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="liveodds__round-items"
          >
            {CryptoModul?.map((item, index2) => {
              return (
                <div className="liveodds__round-item" key={index2}>
                  <div className="liveodds__round-item-title">
                    <h6>Pool {item?.pool}</h6>
                  </div>
                  <div className="liveodds__round-item-inner">
                    <div className={"liveodds__round-item-info"}>
                      <div className="liveodds__round-item-info-inner">
                        <div className="liveodds__round-item-info-image">
                          <img
                            src={
                              process.env.PUBLIC_URL + item?.crypto3?.imageLive
                            }
                            alt=""
                          />
                        </div>
                        <h6>{item?.crypto3?.name}</h6>
                      </div>
                      {itemData?.isActive && (
                        <div className="liveodds__round-item-info-stats">
                          <h5>{item?.crypto3?.operator}</h5>
                          <p>{item?.crypto3?.probability}</p>
                        </div>
                      )}
                    </div>
                    <div className={"liveodds__round-item-info"}>
                      <div className="liveodds__round-item-info-inner">
                        <div className="liveodds__round-item-info-image">
                          <img
                            src={
                              process.env.PUBLIC_URL + item?.crypto2?.imageLive
                            }
                            alt=""
                          />
                        </div>
                        <h6>{item?.crypto2?.name}</h6>
                      </div>
                      {itemData?.isActive && (
                        <div className="liveodds__round-item-info-stats">
                          <h5>{item?.crypto2?.operator}</h5>
                          <p>{item?.crypto2?.probability}</p>
                        </div>
                      )}
                    </div>
                    <div className={"liveodds__round-item-info"}>
                      <div className="liveodds__round-item-info-inner">
                        <div className="liveodds__round-item-info-image">
                          <img
                            src={
                              process.env.PUBLIC_URL + item?.crypto1?.imageLive
                            }
                            alt=""
                          />
                        </div>
                        <h6>{item?.crypto1?.name}</h6>
                      </div>
                      {itemData?.isActive && (
                        <div className="liveodds__round-item-info-stats">
                          <h5>{item?.crypto1?.operator}</h5>
                          <p>{item?.crypto1?.probability}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PredictionItem = ({ itemData, index, active, setActive, form }) => {
  return (
    <div
      className={
        "liveodds__predict " + (active?.id === itemData?.id ? "active" : "")
      }
      onClick={() => {
        if (active?.id === itemData?.id) {
          setActive(null);
        } else {
          setActive(itemData);
        }
      }}
    >
      <div className="liveodds__predict-title ">
        <h6>Round {index + 1}</h6>
        {chevronBottom2}
      </div>
      <AnimatePresence>
        {active?.id === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="liveodds__predict-items"
          >
            {CryptoModul?.map((item, index2) => {
              return (
                <div className="liveodds__predict-item" key={index2}>
                  <div className="liveodds__predict-item-title">
                    <h6>Pool {item?.pool}</h6>
                  </div>
                  <div className="liveodds__predict-item-inner">
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0 && itemData?.isActive
                          ? form?.round1?.filter(
                              (filterItem) => filterItem.id === item.id
                            )[0].cryptoChosen.code === item.crypto3.code
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                      itemData?.isActive &&
                      form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      )[0].cryptoChosen.code === item.crypto3.code ? (
                        <>
                          <div className="liveodds__predict-item-info-icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                item?.crypto3?.imageActive
                              }
                              alt=""
                            />
                          </div>
                          <h6>{item?.crypto3?.name}</h6>
                        </>
                      ) : (
                        <>
                          <div className="liveodds__predict-item-info-inner">
                            <div className="liveodds__predict-item-info-icon">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  item?.crypto3?.imageLive
                                }
                                alt=""
                              />
                            </div>
                            <h6>{item?.crypto3?.name}</h6>
                          </div>
                        </>
                      )}

                      {itemData?.isActive && (
                        <p>
                          36 <span>%</span>
                        </p>
                      )}
                    </div>
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0 && itemData?.isActive
                          ? form?.round1?.filter(
                              (filterItem) => filterItem.id === item.id
                            )[0].cryptoChosen.code === item.crypto2.code
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                      itemData?.isActive &&
                      form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      )[0].cryptoChosen.code === item.crypto2.code ? (
                        <>
                          <div className="liveodds__predict-item-info-icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                item?.crypto2?.imageActive
                              }
                              alt=""
                            />
                          </div>
                          <h6>{item?.crypto2?.name}</h6>
                        </>
                      ) : (
                        <>
                          <div className="liveodds__predict-item-info-inner">
                            <div className="liveodds__predict-item-info-icon">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  item?.crypto2?.imageLive
                                }
                                alt=""
                              />
                            </div>
                            <h6>{item?.crypto2?.name}</h6>
                          </div>
                        </>
                      )}
                      {itemData?.isActive && (
                        <p>
                          49 <span>%</span>
                        </p>
                      )}
                    </div>
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0 && itemData?.isActive
                          ? form?.round1?.filter(
                              (filterItem) => filterItem.id === item.id
                            )[0].cryptoChosen.code === item.crypto1.code
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                      itemData?.isActive &&
                      form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      )[0].cryptoChosen.code === item.crypto1.code ? (
                        <>
                          <div className="liveodds__predict-item-info-icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                item?.crypto1?.imageActive
                              }
                              alt=""
                            />
                          </div>
                          <h6>{item?.crypto1?.name}</h6>
                        </>
                      ) : (
                        <>
                          <div className="liveodds__predict-item-info-inner">
                            <div className="liveodds__predict-item-info-icon">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  item?.crypto1?.imageLive
                                }
                                alt=""
                              />
                            </div>
                            <h6>{item?.crypto1?.name}</h6>
                          </div>
                        </>
                      )}
                      {itemData?.isActive && (
                        <p>
                          15 <span>%</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
