import PassContentsHeader from "./PassContentsHeader";
import GameContentsHeader from "./GameContentsHeader";
import ContentsHeaderDateDisplay from "./ContentsHeaderDateDisplay";

import { getExtraLeagueClass } from "../../Helpers";

const ContentsHeader = ({ tab, isPassForCategory, title, isMonthly }) => (
    <div className={"content__head " + getExtraLeagueClass(isPassForCategory)}>
        <div
            className={"content__head-title " + getExtraLeagueClass(isPassForCategory)}
        >
            <h2 className="uniq">{title}</h2>

            {isPassForCategory ? <PassContentsHeader /> : <GameContentsHeader title={"GAME 1"} />}
        </div>
        <ContentsHeaderDateDisplay tab={tab} isMonthly={isMonthly} />

    </div >
);

export default ContentsHeader;