import React, { useEffect, useState } from "react";
import {
  cancelIcon,
  expandIcon,
  tickIcon,
  userIcon,
} from "../../SVG";
import { AnimatePresence, motion } from "framer-motion";
import { RaceModul } from "../../RaceModul";
import { FormulaModul } from "../DriverList";

export default function Historical() {
  const [resultsModul, setResultsModul] = useState({
    R001: [
      {
        id: "1",
        team: "A",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "2",
        team: "B",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "3",
        team: "C",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "4",
        team: "D",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "5",
        team: "E",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "6",
        team: "F",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "7",
        team: "G",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "8",
        team: "H",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "9",
        team: "I",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "10",
        team: "J",
        true_false: true,
        selected_driver: 1,
      },
    ],
    R002: [
      {
        id: "1",
        team: "A",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "2",
        team: "B",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "3",
        team: "C",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "4",
        team: "D",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "5",
        team: "E",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "6",
        team: "F",
        true_false: true,
        selected_driver: 2,
      },
      {
        id: "7",
        team: "G",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "8",
        team: "H",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "9",
        team: "I",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "10",
        team: "J",
        true_false: true,
        selected_driver: 1,
      },
    ],
    R003: [
      {
        id: "1",
        team: "A",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "2",
        team: "B",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "3",
        team: "C",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "4",
        team: "D",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "5",
        team: "E",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "6",
        team: "F",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "7",
        team: "G",
        true_false: true,
        selected_driver: 2,
      },
      {
        id: "8",
        team: "H",
        true_false: false,
        selected_driver: 2,
      },
      {
        id: "9",
        team: "I",
        true_false: true,
        selected_driver: 1,
      },
      {
        id: "10",
        team: "J",
        true_false: false,
        selected_driver: 2,
      },
    ],
  });
  const [active, setActive] = useState(null);

  return (
    <div className="content__history">
      <div className="content__history-items">
        {RaceModul?.map((item, index) => {
          return (
            <ResultItem
              itemData={item}
              key={index}
              resultsModul={resultsModul}
              setActive={setActive}
              active={active}
            />
          );
        })}
      </div>
    </div>
  );
}
const ResultItem = ({ itemData, resultsModul, setActive, active }) => {
  const [points, setPoints] = useState(0);
  useEffect(() => {
    let points = 0;
    resultsModul?.[itemData?.round]?.map((item) => {
      if (item?.true_false === true) {
        points = points + 1;
      }
    });
    setPoints(points);
  }, []);

  return (
    <div
      className={
        "content__history-item " + (active === itemData?.id ? "active" : "")
      }
    >
      <div
        className={
          "content__history-item-title " +
          (active === itemData?.id ? "active" : "")
        }
        onClick={() => {
          if (active === itemData?.id) {
            setActive(null);
          } else {
            setActive(itemData?.id);
          }
        }}
      >
        <h6>{itemData?.where}</h6>
        <div className="content__history-item-title-info">
          <h5>{points < 10 ? `0${points}` : points}</h5>
          <img
            src={process.env.PUBLIC_URL + "/images/icons/tickIcon.svg"}
            alt=""
          />
          <span></span>
          <h5>Points</h5>
        </div>
        {expandIcon}
      </div>
      <AnimatePresence>
        {active === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 10 }}
            className={`content__history-item-content  ${
              active ? "active" : ""
            } `}
          >
            {FormulaModul?.map((item, index) => {
              const resultInfo = resultsModul?.[itemData?.round]?.filter(
                (filterItem) => filterItem?.team === item?.teamCode
              )[0];
              return (
                <div className="content__history-item-row" key={index}>
                  <div className="content__history-item-row-info">
                    <div className="content__history-item-row-info-icon">
                      {userIcon}
                    </div>
                    <div className="content__history-item-row-info-box">
                      <h6>
                        {resultInfo
                          ? resultInfo?.selected_driver === 1
                            ? item?.driver1?.name
                            : item?.driver2?.name
                          : "-"}
                      </h6>
                      {resultInfo?.true_false === true ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/tickIcon.svg"
                          }
                        />
                      ) : resultInfo?.true_false === false ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/closeIcon.svg"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <h5>{item?.teamNameSm}</h5> */}
                  <div className="content__history-item-row-body">
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_driver === 1 &&
                        resultInfo?.true_false === true
                          ? "selected"
                          : resultInfo?.selected_driver === 2 &&
                            resultInfo?.true_false === false
                          ? "rightone"
                          : "")
                      }
                    >
                      <h6>{item?.driver1?.name}</h6>
                    </div>
                    <div className="content__history-item-row-body-image">
                      <img
                        src={process.env.PUBLIC_URL + "/images/vs.png"}
                        alt=""
                      />
                    </div>
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (resultInfo?.selected_driver === 2 &&
                        resultInfo?.true_false === true
                          ? "selected"
                          : resultInfo?.selected_driver === 1 &&
                            resultInfo?.true_false === false
                          ? "rightone"
                          : "")
                      }
                    >
                      <h6>{item?.driver2?.name}</h6>
                    </div>
                  </div>
                  {/* <div
                    className={
                      "content__history-item-icon " +
                      (resultInfo?.true_false ? "selected" : "")
                    }
                  >
                    {resultInfo?.true_false === true
                      ? tickIcon
                      : resultInfo?.true_false === false
                      ? cancelIcon
                      : ""}
                  </div> */}
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
