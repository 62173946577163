import React from "react";
import ContentsDropdown from "./ContentsDropdown";
import ContentsCardItem from "./ContentsCardItem";
// import { mmaPoolData } from "../MMA/MMAPoolData";

export default function ContentsLiveEvent({
  sentence1,
  sentence2,
  setModal,
  updateForm,
  form,
  categoryData,
  active,
  poolData,
  activePlayed,
  playersList
}) {

  return (
    <>
      <div className="content__inst">
        <p className="sm">{sentence1}</p>
        <button
          type="button"
          className="content__inst-btn"
          onClick={() => setModal("inst")}
        >
          See full instructions
        </button>
      </div>
      <div className="content__dropdowns">
        {poolData?.map((item, index) => {
          return (
            <ContentsDropdown
              itemData={item}
              key={index}
              active={active}
              playersList={playersList}
            >
              {active >= 0 && index === active ? (
                <div className="content__cards">
                  {item?.participants?.map((item2, index2) => {
                    return (
                      <ContentsCardItem
                        key={index2}
                        sentence2={sentence2}
                        index={index2}
                        itemData={item2}
                        form={form}
                        updateForm={updateForm}
                        categoryData={categoryData}
                        active={active}
                        activePlayed={activePlayed}
                        playersList={playersList}
                      />
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </ContentsDropdown>
          );
        })}
      </div>
    </>
  );
}
