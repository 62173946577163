const ContentsTabLabel = ({ tab, isPassForCategory, categoryName }) => {
  if (tab === "live-event") {
    return (
      <>
        {categoryName} <span>free-to-play</span>
      </>
    );
  } else if (tab === "historical") {
    return isPassForCategory ? "predict-to-earn" : (
      <>
        {categoryName} <span>free-to-play</span>
      </>
    );
  } else if (tab === "leaderboard") {
    return "predict-to-earn";
  }
  return null;
};

export default ContentsTabLabel;