/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useContext } from "react";
import { formulaIcon, mmaIcon, twitterIcon } from "../SVG";
import { NavLink } from "react-router-dom";
import { FirstContext } from "../../Context/FirstContext";
import { getExtraSideClasses, handleTooltip } from "./SideHelpers";
import SideLinks from "./SideLinks";
import SideConnected from "./SideConnected";
import SideDisconnected from "./SideDisconnected";
import SideCategoryElement from "./SideCategoryElement";
import SideGetSpredictPass from "./SideGetSpredictPass";
import SideUncategorisedButton from "./SideUncategorisedButton";
import SideSocials from "./SideSocials";

export default function Side({
  pass,
  close,
  menu,
  setModal,
  rewards,
  connected,
  setConnected,
  isDefi,
  type,
}) {
  const [tooltip, setTooltip] = useState(false);
  // const [tooltip2, setTooltip2] = useState(false);
  const [tooltip3, setTooltip3] = useState(false);
  const [tooltip4, setTooltip4] = useState(false);

  const { connectWallet, disconnectWallet, account, shortedAddress } =
    useContext(FirstContext);

  return (
    <div className={"side " + getExtraSideClasses(menu, pass?.[type], type)} onClick={close}>
      <div className="side__inner">

        <SideLinks tooltip={tooltip4} setTooltip={setTooltip4} />
        {
          account
            ? <SideConnected
              account={account}
              shortedAddress={shortedAddress}
              disconnectWallet={disconnectWallet}
            />
            : <SideDisconnected
              isDefi={isDefi}
              setModal={setModal}
              connectWallet={connectWallet}
            />
        }
        {!isDefi && (
          <>
            {
              type === "formula1" && <SideCategoryElement
                isPassAvailable={pass?.formula1}
                categoryIcon={formulaIcon}
                categoryTitle="Formula1"
                handleTooltip={handleTooltip}
                tooltip={tooltip}
                setTooltip={setTooltip}
                tooltip3={tooltip3}
                setTooltip3={setTooltip3}
                setModal={setModal}
                secondaryTitle={<h4>Clash of Champions</h4>}
                tertiaryTitle={<h4>Rival Racers</h4>}
                passOfType={pass?.[type]}
                rewards={rewards}
              />
            }
            {
              type === "mma" && <SideCategoryElement
                isPassAvailable={pass?.mma}
                categoryIcon={mmaIcon}
                categoryTitle="MMA"
                handleTooltip={handleTooltip}
                tooltip={tooltip}
                setTooltip={setTooltip}
                tooltip3={tooltip3}
                setTooltip3={setTooltip3}
                setModal={setModal}
                secondaryTitle={<h4>Face <br /> Off</h4>}
                tertiaryTitle={<h4>Finish <br /> Style</h4>}
                passOfType={pass?.[type]}
                rewards={rewards}
              />
            }

            {
              type === "crypto" && <SideCategoryElement
                isPassAvailable={pass?.crypto}
                categoryIcon={""}
                categoryTitle="crypto"
                handleTooltip={handleTooltip}
                tooltip={tooltip}
                setTooltip={setTooltip}
                tooltip3={tooltip3}
                setTooltip3={setTooltip3}
                setModal={setModal}
                secondaryTitle={<h4>The<br />Predictooor</h4>}
                tertiaryTitle={<h4>The <br />Guessooor</h4>}
                passOfType={pass?.[type]}
                rewards={rewards}
              />
            }

            <div className="side__other">
              <div className="side__btns">
                {type !== "formula1" && (
                  <NavLink /* to={"/f1"} */ className="side__btn">
                    <div className="side__btn-inner">
                      {formulaIcon} FORMULA 1
                    </div>
                  </NavLink>
                )}
                {type !== "mma" && (
                  <NavLink to={"/"} className="side__btn">
                    <div className="side__btn-inner">{mmaIcon} MMA</div>
                  </NavLink>
                )}
                {type !== "crypto" && (
                  <NavLink /* to={"/crypto"} */ className="side__btn">
                    <div className="side__btn-inner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/cryptoIcon.svg"
                        }
                        alt=""
                      />{" "}
                      CRYPTO
                    </div>
                  </NavLink>
                )}
               
                {!pass?.[type] && (
                  <SideUncategorisedButton
                    handleTooltip={handleTooltip}
                    setTooltip3={setTooltip3}
                    tooltip3={tooltip3}
                  />
                )}
              </div>
            </div>
          </>
        )}
        
        <SideSocials twitterIcon={twitterIcon} />

      </div>
      <SideGetSpredictPass setModal={SideGetSpredictPass} />
    </div>
  );
}
