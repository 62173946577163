import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { chevronBottom2 } from "../SVG";

const ContentsDropdown = ({
  active,
  children,
  itemData,
  playersList
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className={
        "content__dropdown " + (active === itemData?.index ? "active" : "")
      }
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className="content__dropdown-title">
        {active >= 0 && active === itemData?.index ? (
          <>
            <h3>{itemData?.title}</h3>
            <div className="content__dropdown-title-bets">
              <p>{active < playersList.length ? playersList[active].length : "0"} betS</p>
            </div>
          </>
        ) : (
          <>
            <AnimatePresence>
              {showTooltip ? (
                <motion.h3
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  exit={{ opacity: 0 }}
                >
                  {itemData?.tooltipText}
                </motion.h3>
              ) : (
                <h3>
                  {itemData?.title}
                  {itemData?.when && <span> {itemData?.when}</span>}
                </h3>
              )}
            </AnimatePresence>
            {chevronBottom2}
          </>
        )}
      </div>
      <AnimatePresence>
        {active === itemData?.index && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="content__dropdown-body"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ContentsDropdown;
