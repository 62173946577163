import React from "react";
import { closeIcon } from "../SVG";

export default function InstructionMod({ setModal, pass }) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-elem"></div>
      <div className="modal__inner-title">
        <h3 className="sm">The Predictooor</h3>
        <h6 className="sm">Instructions</h6>
      </div>
      <div className="modal__inner-head">
        {/* <h3 className="sm">Who will be victorious?</h3> */}
        {pass?.crypto ? (
          <p className="light">
            Select one pick per pool either if you are bullish, bearish or you
            feel the asset will range in the dedicated period (either weekly or
            monthly). If your picked the right choice, you earn points and get
            ranked in the leaderboard. If you finish in the winning spots of the
            leaderboard you’ll earn a piece of the prize pot.
          </p>
        ) : (
          <p>
            Select one pick per pool for either bullish, bearish or a range for
            the given time period (either weekly or monthly). If you pick the
            correct choice, you will earn SPI tokens.
          </p>
        )}
      </div>
      <div className="modal__inner-steps">
        <div className="modal__inner-step">
          <h6>Step 1</h6>
          {pass?.crypto ? (
            <p>Choose one price movement per asset in the pool.</p>
          ) : (
            <p>Choose one price prediction per asset in the pool.</p>
          )}
        </div>
        <div className="modal__inner-step">
          <h6>Step 2</h6>
          {pass?.crypto ? (
            <>
              <p className="fz16">
                Validate your selection through a free transaction in the
                blockchain.
                <br />
                <br />
              </p>

              <p className="light">
                Once the timer is out for a pool, the smart contract is setting
                the price range for each choice, either Bullish, Range or
                Bearish. <br />
                <br />
              </p>
              <p className="light">
                For the CMC Top 20, the price is triggered by the smart contract
                and the price range is +5% and -5%. Any other token/asset will
                have a +7.5%/-7.5% price range. <br />
                <br />
              </p>
              <p className="light small">
                Example: Once the timer is out, the asset/token of the pool A is
                triggered at a $100 price. The Range pick value will be between
                $95 and $105, any value below $95 will be Bearish while any
                value above $105 will be considered Bullish. The pool is locked
                during the whole period considered either weekly or monthly.
                Depending on the price of the asset at the unlock of the pool,
                the smart contract will trigger the winners and provide them
                points in the leaderboard
              </p>
            </>
          ) : (
            <>
              <p className="fz16">
                Validate your selection through a free transaction in the
                blockchain. <br />
                <br />
              </p>
              <p className="fz14">
                Subscribe to our <span>Spredict Pass</span> for opportunities to
                win larger prizes!
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
