const SideCryptoButton = ({isPassAvailable, categoryTitle})=>{
    return (
        <button type="button" className={"side__btn active " + (isPassAvailable ? "league" : "")}>
            <div className="side__btn-inner">
            <div className="side__btn-icon">
                <img className="pc" src={process.env.PUBLIC_URL + "/images/icons/cryptoIcon2.svg"} alt=""/>
                <img className="mob" src={process.env.PUBLIC_URL + "/images/icons/cryptoIcon3.svg"} alt=""/>
            </div>
            <div className="side__btn-content">
                {isPassAvailable && <h6>PASS</h6>}
                <h5>free play</h5>
                <p>{categoryTitle}</p>
            </div>
            </div>
      </button>
)};

export default SideCryptoButton;