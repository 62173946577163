import React, { useState } from "react";

import ContentsHeader from "./ContentsHeader";
import ContentsInfo from "../Contents/ContentsInfo";
import ContentsButton from "../Contents/ContentsButton";
import ContentsTab from "../Contents/ContentsTab";
import ContentsFooter from "../Contents/ContentsFooter";
import PositionedSnackbar from "../F1/PositionedSnackbar";

import SimpleBackdrop from "../F1/SimpleBackdrop";


import {
    getTitle,
    getContentExtraClasses,
    ContentValidateParser,
    getExtraCategoryContentsClasses
} from "../Helpers.jsx"

const Contents = ({
    sentence1,
    sentence2,
    setModal,
    form,
    updateForm,
    categoryData,
    active,
    isPassForCategory,
    categoryName,
    categoryPredCount,
    categoryIcon,
    setTab,
    tab,
    poolData,
    activePlayed,
    bet,
    openMessage,
    setOpenMessage,
    openSuccessMessage,
    setOpenSuccessMessage,
    openErrorMessage,
    setOpenErrorMessage,
    openBackdrop,
    setOpenBackdrop,
    playersList
}) => {
    const [isMonthly, setIsMonthly] = useState(true);
    const [tooltip3, setTooltip3] = useState(false);

    return (
        <div
            className={"content " + getExtraCategoryContentsClasses(categoryName) + getContentExtraClasses(form, tab, categoryPredCount)}
        >

            <div className="content__inner">
                <div className="content__circ">
                    <ContentsHeader
                        tab={tab}
                        isPassForCategory={isPassForCategory}
                        title={getTitle(tab, isPassForCategory, categoryName)} // to handle
                        isMonthly={isMonthly}
                    />
                    <ContentsInfo
                        tab={tab}
                        isPassForCategory={isPassForCategory}
                        isMonthly={isMonthly}
                        setModal={setModal}
                        categoryName={categoryName}
                        categoryIcon={categoryIcon}
                        categoryPredCount={categoryPredCount}
                        userPredCount={active >= 0 && active < form.length ? form[active].length : 0}
                        activePlayed={activePlayed}
                        categoryData={categoryData}
                        active={active}
                    />
                    <ContentsButton
                        tab={tab}
                        setTab={setTab}
                        isPassForCategory={isPassForCategory}
                        setTooltip3={setTooltip3}
                        tooltip3={tooltip3}
                    />
                </div>
                <ContentsTab
                    sentence1={sentence1}
                    sentence2={sentence2}
                    tab={tab}
                    setModal={setModal}
                    form={form}
                    updateForm={updateForm}
                    categoryData={categoryData}
                    categoryName={categoryName}
                    active={active}
                    setIsMonthly={setIsMonthly}
                    isMonthly={isMonthly}
                    poolData={poolData}
                    activePlayed={activePlayed}
                    playersList={playersList}
                />
                <ContentsFooter />
            </div>
            <ContentValidateParser
                form={form}
                active={active}
                categoryData={categoryData}
                activePlayed={activePlayed}
                tab={tab}
                predCount={categoryPredCount}
                bet={bet}
            />

            <PositionedSnackbar
                open={openMessage}
                setOpen={setOpenMessage}
                message="WRONG NETWORK"
                vertical="top"
                horizontal="center"
                success={false}
            />

            <PositionedSnackbar
                open={openSuccessMessage}
                setOpen={setOpenSuccessMessage}
                message="SUCCESS"
                vertical="top"
                horizontal="center"
                success={true}
            />

            <PositionedSnackbar
                open={openErrorMessage}
                setOpen={setOpenErrorMessage}
                message="ERROR"
                vertical="top"
                horizontal="center"
                success={false}
            />

            <SimpleBackdrop open={openBackdrop} setOpen={setOpenBackdrop} />
        </div>
    );
};

export default Contents;
