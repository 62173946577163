export const INVALID_INDEX = 999_999_999_999_999; // max supported decimals

export const CATEGORY_NAME = "MMA";


export const mmaPoolData = [
  {
    title: "ROUND 1 - UFC 311",
    when: "19 Jan 4AM UTC",
    tooltipText: "ROUND NOT LIVE YET",
    index: 0,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Lightweight",
        eventType: "main event",
        fighter1: {
          name: "Makhachev",
          no: "C",
          code: "1",
          flag: "RU",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Moicano",
          no: "10",
          code: "2",
          flag: "BR",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Bantamweight",
        eventType: "",
        fighter1: {
          name: "Dvalishvili",
          no: "C",
          code: "3",
          flag: "GE",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Nurmagomedov",
          no: "02",
          code: "4",
          flag: "RU",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Light Heavyweight",
        eventType: "",
        fighter1: {
          name: "Procházka",
          no: "02",
          code: "5",
          flag: "CZ",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Hill",
          no: "03",
          code: "6",
          flag: "US",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Heavyweight",
        eventType: "",
        fighter1: {
          name: "Almeida",
          no: "06",
          code: "7",
          flag: "BR",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Spivac",
          no: "07",
          code: "8",
          flag: "MD",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  },
  {
    title: "Round 2 - UFC Fight night",
    when: "Sat Feb 1 2025",
    tooltipText: "ROUND NOT LIVE YET",
    index: INVALID_INDEX
  },
  {
    title: "Round 3 - UFC 312",
    when: "Sun Feb 9 2025",
    tooltipText: "ROUND NOT LIVE YET",
    index: INVALID_INDEX
  },
  {
    title: "Round 4 - UFC Fight night",
    when: "Sun Feb 16 2025",
    tooltipText: "ROUND NOT LIVE YET",
    index: INVALID_INDEX
  },
  {
    title: "SPONSOR POOL",
    when: "",
    tooltipText: "Contact the team to sponsor your pool",
    index: INVALID_INDEX
  },
];