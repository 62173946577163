/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import {
  chevronBottom2,
/*   facebookIcon,
 */  twitterIcon,
  userIcon,
/*   youtubeIcon,
 */} from "../SVG";

import { AnimatePresence, motion } from "framer-motion";

// const roundList = [
//   {
//     id: "1",
//     isActive: true,
//   },
//   {
//     id: "2",
//     isActive: false,
//   },
//   {
//     id: "3",
//     isActive: false,
//   },
//   {
//     id: "4",
//     isActive: false,
//   },
// ];

export default function LiveOdds({
  form,
  categoryData,
  poolData,
  active,
  playersList
}) {
  const [liveOddsHidden, setLiveOddsHidden] = useState(true);
  // const [activePred, setActivePred] = useState(null);

  return (
    <div className="liveodds">
      <div className="liveodds__inner">
        <div className="liveodds__title">
          <h5>live odds</h5>
        </div>
        <div className="liveodds__rounds">
          {poolData?.slice(0, poolData.length - 1).map((item, index) => {
            return (
              <LiveOddsItem
                itemData={item}
                key={index}
                active={active}
                // setActive={setActive}
                index={index}
                poolData={poolData}
                categoryData={categoryData}
                playersList={playersList}
                liveOddsHidden={liveOddsHidden}
                setLiveOddsHidden={setLiveOddsHidden}
              />
            );
          })}
        </div>
        <div className="info__socials-outer">
          <div className="info__socials">
            <a
              href="https://twitter.com/spredict_io"
              target="_blank"
              className="info__social"
            >
              {twitterIcon}
            </a>
            <a
              href="https://spredict.io/"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/language.png"}
                alt=""
              />
            </a>

            <a
              href="https://t.me/SpredictOfficialPortal"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/telegram.png"}
                alt=""
              />
            </a>
            <a
              href="https://medium.com/@Spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/medium.png"}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="liveodds__predicts">
          <div className="liveodds__predicts-title">
            <h5>Player predictions</h5>
          </div>
          {/* <div className="liveodds__predicts-inner">
            {poolData?.map((item, index) => {
              return (
                <PredictionItem
                  itemData={item}
                  key={index}
                  active={active}
                  // setActive={setActivePred}
                  index={index}
                  form={form}
                  poolData={poolData}
                />
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
}

const LiveOddsItem = ({ poolData, itemData, index, active, setActive, categoryData, playersList, liveOddsHidden,
  setLiveOddsHidden }) => {


  return (
    <div
      className={
        "liveodds__round " + (active >= 0 && active === index && !liveOddsHidden ? "active" : "")
      }
      onClick={() => {
        if (active < 0 || active !== index) return;
        setLiveOddsHidden(!liveOddsHidden)
      }}
    >
      <div className="liveodds__round-title ">
        <h6>Round {index + 1}</h6>
        {chevronBottom2}
      </div>
      <AnimatePresence>
        {!liveOddsHidden && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="liveodds__round-items"
          >
            {poolData[index].participants?.map((item, index2) => {

              const trueCountIndex = 10;

              const isActive = active < categoryData.length
                && active < playersList.length
                && playersList[active].length > 0
                && index2 < categoryData[active][trueCountIndex].length
                && playersList[active].length >= parseInt(categoryData[active][trueCountIndex]);

              const trueFavorite = index2 >= categoryData[active][trueCountIndex].length
                || parseInt(categoryData[active][trueCountIndex][index2]) * 100 / parseInt(playersList[active].length) === 50
                ? null
                : parseInt(categoryData[active][trueCountIndex][index2]) * 100 / parseInt(playersList[active].length) > 50;


              return (
                <div className="liveodds__round-item" key={index2}>
                  <div className="liveodds__round-item-title">
                    <h6>Pool {item?.pool}</h6>
                  </div>
                  <div className="liveodds__round-item-inner">
                    <div
                      className={
                        "liveodds__round-item-info " +
                        (
                          isActive
                            && trueFavorite === true
                            ? "active"
                            : ""
                        )
                      }
                    >
                      {
                        isActive
                        && trueFavorite === true && (
                          <div className="liveodds__round-item-info-image">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/favorite.svg"
                              }
                              alt=""
                            />
                          </div>
                        )}
                      <h6>{item?.fighter1?.name}</h6>
                      {
                        isActive && (
                          <p>{playersList[active].length > 0 ? parseInt(parseInt(categoryData[active][trueCountIndex][index2]) * 100 / playersList[active].length).toString() + " %" : "0 %"}</p>
                        )}
                    </div>
                    <div
                      className={
                        "liveodds__round-item-info " +
                        (isActive
                          && trueFavorite === false
                          ? "active"
                          : "")
                      }
                    >
                      {
                        isActive
                        && trueFavorite === false && (
                          <div className="liveodds__round-item-info-image">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/favorite.svg"
                              }
                              alt=""
                            />
                          </div>
                        )}
                      <h6>{item?.fighter2?.name}</h6>
                      {isActive && (
                        <p>{
                          playersList[active].length > 0
                            ? parseInt((playersList[active].length - parseInt(categoryData[active][trueCountIndex][index2])) * 100 / playersList[active].length).toString() + " %"
                            : "0 %"
                        }</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const PredictionItem = ({ itemData, index, active, setActive, form, poolData }) => {
  return (
    <div
      className={
        "liveodds__predict " + (active?.id === itemData?.id ? "active" : "")
      }
    // onClick={() => {
    //   if (active?.id === itemData?.id) {
    //     setActive(null);
    //   } else {
    //     setActive(itemData);
    //   }
    // }}
    >
      <div className="liveodds__predict-title ">
        <h6>Round {index + 1}</h6>
        {chevronBottom2}
      </div>
      <AnimatePresence>
        {active?.id === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, Y: 20 }}
            className="liveodds__predict-items"
          >
            {poolData?.map((item, index2) => {
              return (
                <div className="liveodds__predict-item" key={index2}>
                  <div className="liveodds__predict-item-title">
                    <h6>Pool {item?.pool}</h6>
                  </div>
                  <div className="liveodds__predict-item-inner">
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0
                          ? form?.round1?.filter(
                            (filterItem) => filterItem.id === item.id
                          )[0].fighterChosen.code === item.fighter1.code &&
                            itemData?.isActive
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                        form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        )[0].fighterChosen.code === item.fighter1.code &&
                        itemData?.isActive && (
                          <div className="liveodds__predict-item-info-icon">
                            {userIcon}
                          </div>
                        )}
                      <h6>{item?.fighter1?.name}</h6>
                      {itemData?.isActive && (
                        <p>{item?.fighter1?.probability}</p>
                      )}
                    </div>
                    <div
                      className={
                        "liveodds__predict-item-info " +
                        (form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        ).length > 0
                          ? form?.round1?.filter(
                            (filterItem) => filterItem.id === item.id
                          )[0].fighterChosen.code === item.fighter2.code &&
                            itemData?.isActive
                            ? "active"
                            : ""
                          : "")
                      }
                    >
                      {form?.round1?.filter(
                        (filterItem) => filterItem.id === item.id
                      ).length > 0 &&
                        form?.round1?.filter(
                          (filterItem) => filterItem.id === item.id
                        )[0].fighterChosen.code === item.fighter2.code &&
                        itemData?.isActive && (
                          <div className="liveodds__predict-item-info-icon">
                            {userIcon}
                          </div>
                        )}
                      <h6>{item?.fighter2?.name}</h6>
                      {itemData?.isActive && (
                        <p>{item?.fighter2?.probability}</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
