import React, { useEffect, useState, useContext } from "react";
import { AnimatePresence } from "framer-motion";
import Side from "../Components/Side";
import Content from "../Components/F1/Content";
import Info from "../Components/F1/Info";
import Modal from "../Components/Modal";
import ClashMod from "../Components/F1/ClashMod";
import { RaceModul } from "../Components/RaceModul";
import { FirstContext, currentRaceId } from "../Context/FirstContext";
import InfoMod from "../Components/F1/InfoMod";
import Tooltip from "../Components/Modals/Tooltip";

export default function F1({
  menu,
  setMenu,
  modal,
  setModal,
  close,
  pass,
}) {
  const [race, setRace] = useState(RaceModul[0]);
  // const { form, updateForm } = useContext(FirstContext);
  const [form, setForm] = useState({
    R001: [],
    R002: [],
    R003: [],
    R004: [],
    R005: [],
    R006: [],
    R007: [],
    R008: [],
    R009: [],
    R010: [],
    R011: [],
    R012: [],
    R013: [],
    R014: [],
    R015: [],
    R016: [],
    R017: [],
    R018: [],
    R019: [],
    R020: [],
    R021: [],
    R022: [],
    R023: [],
    R024: [],
  });

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  const [rewards, setRewards] = useState(true);
  const [tab, setTab] = useState("live-event");
  return (
    <>
      <main>
        <div className="wrapper">
          <Side
            menu={menu}
            close={close}
            pass={pass}
            setModal={setModal}
            setRewards={setRewards}
            rewards={rewards}
            type={"formula1"}
          />
          <Content
            race={race}
            pass={pass}
            setModal={setModal}
            form={form}
            updateForm={updateForm}
            tab={tab}
            setTab={setTab}
          />
          {tab === "live-event" && <Info race={race} />}
        </div>
      </main>
      <AnimatePresence>
        {modal === "inst" && (
          <Modal addClass={"inst"} addClassMod="nopd" setModal={setModal}>
            <ClashMod setModal={setModal} />
          </Modal>
        )}
        {modal === "info" && (
          <Modal addClassMod="nopd" addClass={"infomod"} setModal={setModal}>
            <InfoMod setModal={setModal} race={race} />
          </Modal>
        )}
        {modal === "tooltip" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <Tooltip setModal={setModal} setRewards={setRewards} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
