const ContentButtons = ({ tab, setTab, isPassForCategory, setTooltip3, tooltip3 }) => (
  <div className="content__btns">
    <button
      type="button"
      className={
        "content__btn " + (tab === "live-event" ? "active" : "")
      }
      onClick={() => setTab("live-event")}
    >
      <div className="content__btn-inner">LIVE EVENT</div>
    </button>
    <button
      type="button"
      className={
        "content__btn " + (tab === "historical" ? "active" : "")
      }

    >
      <div className="content__btn-inner">
        Past Results
        {
          <div className={"tooltip " + (tooltip3 ? "active" : "")}>
            <p>
              Work in progress
            </p>
          </div>
        }
      </div>
    </button>
    <button
      type="button"
      className={
        "content__btn " + (tab === "leaderboard" ? "active" : "")
      }
      onClick={() => {
        if (isPassForCategory) {
          setTab("leaderboard");
        } else {
          setTooltip3(true);
          setTimeout(() => {
            setTooltip3(false);
          }, 2000);
        }
      }}
    >
      <div className="content__btn-inner">
        Leaderboard
        {!isPassForCategory && (
          <div className={"tooltip " + (tooltip3 ? "active" : "")}>
            <p>
              Work in progress
              {/* You must register to the Spredict Pass to access the
              leaderboard */}
            </p>
          </div>
        )}
      </div>
    </button>
  </div>
);

export default ContentButtons;