import React, { useState, useEffect, useContext } from "react";
import Side from "../Components/Side";
import { AnimatePresence } from "framer-motion";
import Contents from "../Components/Contents";
import LiveOdds from "../Components/MMA/LiveOdds";
import Modal from "../Components/Modal";
import Tooltip from "../Components/Modals/Tooltip";
import InstructionMod from "../Components/MMA/InstructionMod";
import LiveOddsMod from "../Components/MMA/LiveOddsMod";
import { mmaIcon } from "../Components/SVG";
import { FirstContext } from "../Context/FirstContext";
import { INVALID_INDEX, CATEGORY_NAME, mmaPoolData } from "../Components/MMA/MMAPoolData"



/* 
  struct Session {
        uint256 index;
        uint256 lock;
        uint256 end;
        uint256 predCount; // Renamed from 'length' to 'predCount'
        uint256 fee;
        IERC20Metadata feeToken;
        uint8 category;
        bytes32 root;
        bool counted;
        bool[] results;
        uint256[] trueCount;
    }
*/








/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

export default function MMA({
  menu,
  modal,
  setModal,
  connected,
  setConnected,
  close,
  pass,
}) {

  /* -------------------------------------------------------------------------- */
  /*                                   CONTEXT                                  */
  /* -------------------------------------------------------------------------- */

  const { 
    account,
    sessionsCount,
    dualSessions,
    playersList,
    openMessage,
    setOpenMessage,
    openSuccessMessage,
    setOpenSuccessMessage,
    openErrorMessage,
    setOpenErrorMessage,
    openBackdrop,
    setOpenBackdrop,
    getStaticCategoryData,
    filterOnChainDataByCategory,
    playerChoices,
    bet
  } =
    useContext(FirstContext);

  /* -------------------------------------------------------------------------- */
  /*                                  USE STATE                                 */
  /* -------------------------------------------------------------------------- */

  const [rewards, setRewards] = useState(true);
  const [tab, setTab] = useState("live-event");
  const [staticCategoryData, setStaticCategoryData] = useState(null);
  const [mmaData, setMMAData] = useState([]);
  // const [played, setPlayed] = useState(false);
  const [active, setActive] = useState(-1);
  const [form, setForm] = useState([]);
  const [activePlayed, setActivePlayed] = useState(false);

  const handleForm = async () => {
    if (active < 0) return;
    if (mmaData.length === 0) return;
    if (active >= mmaData.length) return;

    updateForm(playerChoices);
    if (playerChoices[active]?.length > 0) {
      setActivePlayed(true);
    }
  }

  useEffect(() => {
    if (active < 0 || !account) return;
    handleForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, account, playerChoices])

  /* -------------------------------------------------------------------------- */
  /*                                 USE EFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (staticCategoryData === null) {
      const arrayData = getStaticCategoryData(CATEGORY_NAME);
      if (!arrayData) return;
      setStaticCategoryData(arrayData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticCategoryData])


  useEffect(() => {
    if (!dualSessions) return;
    if (dualSessions.length === 0) return;
    if (sessionsCount === 0) return;
    if (staticCategoryData !== null) {
      const arrayData = filterOnChainDataByCategory(staticCategoryData.category);
      if (!arrayData) return;
      if (arrayData.length === 0) return;
      setMMAData(arrayData);
    } else {
      setMMAData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticCategoryData, dualSessions, sessionsCount]);



  // useEffect(()=> {
  //   if(!mmaData || !account) return;
  //   const sessionIndex = parseInt(mmaData[0]);
  //   if(sessionIndex >= playersList.length) return;

  //   setPlayed(!playersList[sessionIndex].every(el => el.toLowerCase() !== account.toLowerCase()))

  // }, [mmaData, account, playersList]);

  useEffect(() => {
    if (mmaData.length === 0) return;
    const now = parseInt(new Date()) / 1000;
    let end = 999_999_999_999_999;
    let closestSession = 0;

    for (let i = 0; i < mmaData.length; i++) {
      const dataEnd = parseInt(mmaData[i][2]);
      if (dataEnd >= now && dataEnd <= end) {
        end = dataEnd;
        closestSession = mmaData[i][0];
      }

      setActive(closestSession);
    }
  }, [mmaData]);

  /* -------------------------------------------------------------------------- */
  /*                                 STATE UTILS                                */
  /* -------------------------------------------------------------------------- */

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RETURN                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <main>
        <div className="wrapper">
          <Side
            menu={menu}
            close={close}
            pass={pass}
            setModal={setModal}
            setRewards={setRewards}
            rewards={rewards}
            connected={connected}
            setConnected={setConnected}
            type={"mma"}
          />
          <Contents
            sentence1="Select one FIGHTER to win per pool"
            sentence2="Pool "
            setModal={setModal}
            form={form}
            updateForm={updateForm}
            categoryData={mmaData}
            active={active}
            isPassForCategory={pass.mma}
            categoryName={CATEGORY_NAME}
            poolData={mmaPoolData}
            categoryPredCount={parseInt(staticCategoryData?.predCount) || INVALID_INDEX}
            categoryIcon={mmaIcon}
            setTab={setTab}
            tab={tab}
            activePlayed={activePlayed}
            bet={bet}
            openMessage={openMessage}
            setOpenMessage={setOpenMessage}
            openSuccessMessage={openSuccessMessage}
            setOpenSuccessMessage={setOpenSuccessMessage}
            openErrorMessage={openErrorMessage}
            setOpenErrorMessage={setOpenErrorMessage}
            openBackdrop={openBackdrop}
            setOpenBackdrop={setOpenBackdrop}
            playersList={playersList}
          />
          {tab === "live-event" && <LiveOdds             
            form={form} 
            categoryData={mmaData}
            poolData={mmaPoolData}
            active={active}
            playersList={playersList}
          />}
        </div>
      </main>
      <AnimatePresence>
        {modal === "inst" && (
          <Modal addClass={"inst"} addClassMod="nopd" setModal={setModal}>
            <InstructionMod pass={pass} setModal={setModal} />
          </Modal>
        )}
        {modal === "liveodds" && (
          <Modal addClassMod="nopd" addClass={"infomod"} setModal={setModal}>
            <LiveOddsMod setModal={setModal} form={form} />
          </Modal>
        )}
        {modal === "tooltip" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <Tooltip setModal={setModal} setRewards={setRewards} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
