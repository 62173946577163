import React, { useState } from "react";
import Side from "../Components/Side";
import { AnimatePresence } from "framer-motion";
import Content from "../Components/Crypto/Content";
import LiveOdds from "../Components/Crypto/LiveOdds";
import Modal from "../Components/Modal";
import Tooltip from "../Components/Modals/Tooltip";
import InstructionMod from "../Components/Crypto/InstructionMod";
import LiveOddsMod from "../Components/Crypto/LiveOddsMod";

export default function Crypto({
  menu,
  modal,
  setModal,
  connected,
  setConnected,
  close,
  pass,
}) {
  const [rewards, setRewards] = useState(true);
  const [tab, setTab] = useState("live-event");
  const [form, setForm] = useState({
    round1: [],
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  return (
    <>
      <main>
        <div className="wrapper">
          <Side
            menu={menu}
            close={close}
            pass={pass}
            setModal={setModal}
            setRewards={setRewards}
            rewards={rewards}
            connected={connected}
            setConnected={setConnected}
            type={"crypto"}
          />
          <Content
            pass={pass}
            setModal={setModal}
            form={form}
            updateForm={updateForm}
            tab={tab}
            setTab={setTab}
          />
          {tab === "live-event" && <LiveOdds form={form} />}
        </div>
      </main>
      <AnimatePresence>
        {modal === "inst" && (
          <Modal
            addClass={"inst crypto"}
            addClassMod="nopd"
            setModal={setModal}
          >
            <InstructionMod pass={pass} setModal={setModal} />
          </Modal>
        )}
        {modal === "liveodds" && (
          <Modal addClassMod="nopd " addClass={"infomod crypto"} setModal={setModal}>
            <LiveOddsMod setModal={setModal} form={form} />
          </Modal>
        )}
        {modal === "tooltip" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <Tooltip setModal={setModal} setRewards={setRewards} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
